import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createMarker, polyline } from '../../utils/drawing/marker';
import { crossFireMarker } from '../../GoogleMap/markers/Markers';
import { locationRequest } from '../../../../../common/api/fetchBaronWeatherSignature';
import EtaButton from './EtaButton';
import EtaInfoWindowContainer from './EtaInfoWindowContainer';
import { GMAP_CONTROLLED_ICON } from '../../../../../common/constants';
import {
  getSelectedTrack,
  getAircraftWithLatestTrackById
} from '../../../../../redux/selectors/aircraftData';
import { getSelectedDisplayPoint } from '../../../../../redux/selectors/eventData';
import { getSelectedMarkerPoint } from '../../../../../redux/selectors/mapData';
import '../../../../../assets/scss/global.scss';
import { gaEventHelper } from '@spidertracks/common';
import { isAircraftActive } from '../../../../../helpers/isAircraftActive';
import { SpiderType } from '../../../../../types/spiders';

const REQUEST_LOCATION_UNSUPPORTED_SPIDER_TYPES = [SpiderType.SX, SpiderType.FLIGHTCELL_DZM];
const etaGAEventSender = gaEventHelper('ETA');
const requestLocationGAEventSender = gaEventHelper('Request Location');
const ETAButtonTextDefault = 'ETA';

export class EtaButtonContainer extends Component {
  moveListener;
  rightClickListener;
  mapMarker;
  flightPath;
  etaInfoWindow;

  static propTypes = {
    isEtaButtonActive: PropTypes.bool,
    aircraftId: PropTypes.string,
    sourceLatLng: PropTypes.object,
    destinationLatLng: PropTypes.object,
    isRequestLocationButtonActive: PropTypes.bool,
    showEvents: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      requestLocationText: 'Request Location',
      etaButtonText: ETAButtonTextDefault,
      etaButtonClicked: false
    };
    this.infoWindowRoot = document.getElementById('infoWindow-root');

    if (!this.infoWindowRoot) {
      this.infoWindowRoot = document.createElement('div');
      this.infoWindowRoot.setAttribute('id', 'infoWindow-root');
      document.querySelector('body').append(this.infoWindowRoot);
    }
  }

  MARKER_ICON = {
    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(crossFireMarker())}`,
    scaledSize: new window.google.maps.Size(
      GMAP_CONTROLLED_ICON.height,
      GMAP_CONTROLLED_ICON.width
    ),
    anchor: new window.google.maps.Point(
      GMAP_CONTROLLED_ICON.positionX,
      GMAP_CONTROLLED_ICON.positionY
    )
  };

  componentDidUpdate(prevProps) {
    if (prevProps.aircraftId !== this.props.aircraftId) {
      this.cleanup();
    }
  }

  componentWillUnmount() {
    this.cleanup();
  }

  // HELPERS

  location = async () => {
    const response = await locationRequest(this.props.aircraftId);
    this.setState({ requestLocationText: 'Sending request' });
    if (response.status === 200) {
      setTimeout(() => {
        this.setState({ requestLocationText: 'Request Sent' });
      }, 500);
      setTimeout(() => {
        this.setState({ requestLocationText: 'Request Location' });
      }, 1000);
    } else {
      this.setState({ requestLocationText: 'Request Location' });
    }
    requestLocationGAEventSender('Request Location', 'Request Location');
  };

  getLatLng(latLng) {
    return { lat: latLng.lat(), lng: latLng.lng() };
  }

  // ADD UI ELEMENTS

  async addETAMarker() {
    const { destinationLatLng } = this.state;
    if (this.mapMarker) {
      this.mapMarker.setPosition(destinationLatLng);
      return;
    }

    const marker = await createMarker({
      ...this.getLatLng(destinationLatLng),
      icon: this.MARKER_ICON,
      isDraggable: true
    });
    this.mapMarker = marker;
    this.mapMarker.addListener('rightclick', this.rightClickHandler);
    this.mapMarker.addListener('drag', ({ latLng: destinationLatLng }) => {
      this.setState({ destinationLatLng });
    });
    this.showInfoPopup(window.gmap, this.mapMarker);
  }

  drawETALine() {
    // only want to draw the line if the props.aircraftId doesn't match prevProps.aircractId
    if (this.flightPath) {
      this.deleteETALine();
    }
    const polylineOptions = {
      path: [this.props.sourceLatLng, this.state.destinationLatLng],
      geodesic: true,
      strokeColor: '#0000FF',
      strokeOpacity: 1.0,
      strokeWeight: 2
    };
    this.flightPath = polyline(polylineOptions);
    this.flightPath.setMap(window.gmap);
  }

  showInfoPopup = (map, marker) => {
    this.etaInfoWindow = new window.google.maps.InfoWindow({
      content: this.infoWindowRoot // dom node
    });
    this.etaInfoWindow.open(map, marker);
    this.etaInfoWindow.addListener('closeclick', () => {
      this.cleanup();
    });
  };

  // EVENT HANDLERS

  onClickETAButton = () => {
    if (!this.state.etaButtonClicked) {
      this.addHandlers();
      this.setState({ etaButtonText: 'Right click to place marker', etaButtonClicked: true });
    } else {
      this.cleanup();
    }
    etaGAEventSender('ETA', 'ETA');
  };

  addHandlers = () => {
    this.addRightClickListener();
    this.addMoveListener();
  };

  removeHandlers = () => {
    this.removeRightClickListener();
    this.removeMoveListener();
  };

  addRightClickListener() {
    this.rightClickListener = window.gmap.addListener('rightclick', this.rightClickHandler);
  }

  addMoveListener() {
    this.moveListener = window.gmap.addListener('mousemove', event => {
      this.setState({ destinationLatLng: event.latLng });
    });
    this.dataMoveListener = window.gmap.data.addListener('mousemove', event => {
      this.setState({ destinationLatLng: event.latLng });
    });
  }

  rightClickHandler = () => {
    this.removeMoveListener();
    this.removeRightClickListener();
    this.setState({ etaButtonText: ETAButtonTextDefault, etaButtonClicked: false });
  };

  // DELETE

  cleanup() {
    if (this.mapMarker) {
      this.etaInfoWindow.close();
      this.setState({
        destinationLatLng: undefined,
        etaButtonText: ETAButtonTextDefault,
        etaButtonClicked: false
      });
      this.deleteETAMarker();
      this.deleteETALine();
      this.removeHandlers();
    }
  }

  deleteETALine = () => {
    if (this.flightPath) {
      this.flightPath.setMap(null);
      this.flightPath = undefined;
    }
  };

  deleteETAMarker = () => {
    if (this.mapMarker) {
      this.mapMarker.setMap(null);
    }
    this.mapMarker = undefined;
  };

  removeRightClickListener = () => {
    if (this.rightClickListener) {
      window.google.maps.event.removeListener(this.rightClickListener);
    }
  };

  removeMoveListener = () => {
    if (this.moveListener) {
      window.google.maps.event.removeListener(this.moveListener);
      this.moveListener = undefined;
    }
    if (this.dataMoveListener) {
      window.google.maps.event.removeListener(this.dataMoveListener);
      this.dataMoveListener = undefined;
    }
  };

  render() {
    const { requestLocationText, etaButtonText, destinationLatLng } = this.state;
    const {
      aircraftId,
      sourceLatLng,
      isEtaButtonActive,
      isRequestLocationButtonActive
    } = this.props;

    if (destinationLatLng && aircraftId) {
      this.drawETALine();
      this.addETAMarker();
    }

    return (
      <Fragment>
        <EtaButton
          aircraftLocation={this.location}
          requestLocationText={requestLocationText}
          onClickETAButton={this.onClickETAButton}
          etaButtonText={etaButtonText}
          isEtaButtonActive={isEtaButtonActive}
          isRequestLocationButtonActive={isRequestLocationButtonActive}
        />
        {ReactDOM.createPortal(
          <EtaInfoWindowContainer
            destinationLatLng={destinationLatLng}
            sourceLatLng={sourceLatLng}
          />,
          this.infoWindowRoot
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const selectedTrack = getSelectedTrack(state);
  if (selectedTrack) {
    const selectedMarkerPoint = ownProps.showEvents
      ? getSelectedDisplayPoint(state)
      : getSelectedMarkerPoint(state);
    const aircraft = getAircraftWithLatestTrackById(state, selectedTrack.aircraft.id)[0];
    const rawSpeed =
      selectedMarkerPoint && selectedMarkerPoint.speed_raw
        ? selectedMarkerPoint.speed_raw.value
        : 0;
    const isActive = isAircraftActive(aircraft);
    const isRequestLocationSupported = selectedTrack.spider.type
      ? !REQUEST_LOCATION_UNSUPPORTED_SPIDER_TYPES.includes(selectedTrack.spider.type)
      : true;

    return {
      isEtaButtonActive: isActive && !!rawSpeed,
      isRequestLocationButtonActive: isActive && isRequestLocationSupported,
      aircraftId: aircraft ? aircraft.id : undefined,
      sourceLatLng: new window.google.maps.LatLng({
        lat: selectedMarkerPoint.latitude,
        lng: selectedMarkerPoint.longitude
      })
    };
  }
  return {};
};

export default connect(mapStateToProps)(EtaButtonContainer);
