import { toDegrees } from '../../../../helpers/toDegrees';
import { convertMetresTo } from './helper';
import { formatSpeedFromMetersPerSeconds } from '../../../../helpers/formatSpeed';
import { getEventDisplayConfig } from './eventDisplayConfig';
import { FSI_SEVERITY_MAP } from '../../../../util/mapEventsToTrackPoints/fsi';

const getConvertedValue = (key, value) => {
  if (key.unit === 'radians' && key.displayUnit === 'degrees') {
    return Math.abs(Math.round(toDegrees(value)));
  }

  if (key.unit === 'm' && key.displayUnit === 'ft') {
    return convertMetresTo(value, 'ft', 0)[0];
  }

  if (key.unit === 'm/s' && key.displayUnit === 'kt') {
    return Math.abs(Math.round(formatSpeedFromMetersPerSeconds(value, 'knots')));
  }

  if (key.unit === 'm/s' && key.displayUnit === 'ft/min') {
    return Math.abs(Math.round(formatSpeedFromMetersPerSeconds(value, 'ft/min')));
  }

  if (key.unit === 'g' && key.displayUnit === 'g') {
    return value;
  }
};

const getDisplayString = config => {
  let displayString = config.displayFormatString;
  config.keys.forEach(key => {
    displayString = displayString.replace(`{${key.name}}`, key.convertedValue);
  });
  return displayString;
};

export const getEventDisplayValues = event => {
  if (
    !!event &&
    !!event.eventType &&
    !!event.eventDetails &&
    !!event.eventDetails.eventValues &&
    !!event.eventDetails.thresholdConfiguration
  ) {
    const eventDisplayConfig = getEventDisplayConfig(event.eventType);

    if (!!eventDisplayConfig) {
      const eventThresholds = event.eventDetails.thresholdConfiguration;
      const eventValues = event.eventDetails.eventValues;
      eventDisplayConfig.thresholdConfig.keys.forEach(key => {
        const eventThreshold = eventThresholds[key.name];
        const convertedValue = getConvertedValue(key, eventThreshold);
        key['convertedValue'] = convertedValue;
      });

      eventDisplayConfig.valueConfig.keys.forEach(key => {
        const eventValue = eventValues[key.name];
        const convertedValue = getConvertedValue(key, eventValue);
        key['convertedValue'] = convertedValue;
      });

      const thresholdDisplayString = getDisplayString(eventDisplayConfig.thresholdConfig);
      const valueDisplayString = getDisplayString(eventDisplayConfig.valueConfig);

      if (!!event.eventDetails.severity) {
        const severityDisplayString =
          FSI_SEVERITY_MAP[event.eventDetails.severity]?.displayString || '';

        const durationDisplayString = `${Math.ceil(
          (event.eventDetails.windowEndTimeEpochMilis -
            event.eventDetails.windowStartTimeEpochMilis) /
            1000
        )} seconds`;

        const peakBreachValues = event.eventDetails.peakBreachValues;
        eventDisplayConfig.valueConfig.keys.forEach(key => {
          const peakBreachValue = peakBreachValues[key.name];
          const convertedPeakBreachValue = getConvertedValue(key, peakBreachValue);
          key['convertedValue'] = convertedPeakBreachValue;
        });
        const peakBreachValueDisplayString = getDisplayString(eventDisplayConfig.valueConfig);

        return {
          thresholdDisplayString,
          valueDisplayString,
          severityDisplayString,
          durationDisplayString,
          peakBreachValueDisplayString,
          displayName: eventDisplayConfig.displayName
        };
      }

      return {
        thresholdDisplayString,
        valueDisplayString,
        displayName: eventDisplayConfig.displayName
      };
    }
  }
  return undefined;
};
