import moment from 'moment-timezone';
import { formatSpeedFromMetersPerSeconds } from '../../../../helpers/formatSpeed';
import { dateFormatter, formatStandardDateStrings } from '../../../../helpers/formatTime';
import { UserData } from '../../../../redux/types';
import { WidgetDomObject, WidgetMetadata, WidgetQueryResult } from './sisenseTypes';
import { convertMetresTo } from '../utils/helper';
import { SisenseConstants } from './SisenseConstants';

const FT_UNIT = 'ft';
const FTPMIN_UNIT = 'ft/min';
const DEGREES_UNIT = 'degrees';
const KNOTS_UNIT = 'knots';

/**
 * Function which compares the widge series name against the series name used in the widget creation.
 * Created since the series name defined in the widget may change when rendered in FE to add the user preferred unit. For example, the series name "GPS Speed" becomes "GPS Speed (m/s)".
 * Returns true if the series name in the widget matches with the one used to create the widget.
 *
 * @param widgetSeriesName series name in the widget after any changes
 * @param originalSeriesName series name used while creating the widget
 * @returns true if both names are an exact match or if the chart series name ends with the unit enclosed in brackets
 */
export const isSeriesNameMatching = (
  widgetSeriesName: string,
  originalSeriesName: string | undefined
): boolean => {
  if (!!widgetSeriesName && !!originalSeriesName) {
    if (widgetSeriesName === originalSeriesName) {
      return true;
    }

    if (widgetSeriesName.startsWith(originalSeriesName)) {
      const units = widgetSeriesName.substring(originalSeriesName.length).trim();
      const unitRegex = new RegExp(/^\(\S+\)$/gm);
      return unitRegex.test(units);
    }
  }

  return false;
};

const roundValue = (value: number): number => Math.round(value * 100) / 100;

export const convertSeriesSpeedUnit = (queryResult: WidgetQueryResult, userData: UserData) => {
  const speedSeries = queryResult.series.find(series =>
    isSeriesNameMatching(series.name, SisenseConstants.SERIES_SPEED)
  );

  if (!!speedSeries) {
    for (let speed of speedSeries.data) {
      if (!!speed.y) {
        speed.y = roundValue(formatSpeedFromMetersPerSeconds(speed.y, userData.speedUnit));
      }
    }
  }
};

export const convertSeriesAltitudeUnit = (queryResult: WidgetQueryResult, userData: UserData) => {
  const altitudeMSLSeries = queryResult.series.find(series =>
    isSeriesNameMatching(series.name, SisenseConstants.SERIES_ALTITUDE_MSL)
  );
  if (!!altitudeMSLSeries) {
    for (let altitude of altitudeMSLSeries.data) {
      if (!!altitude.y) {
        altitude.y = roundValue(convertMetresTo(altitude.y, userData.altitudeUnit)[0]);
      }
    }
  }

  // Altitude AGL is converted to a hardcoded unit (feet) for now
  const altitudeAGLSeries = queryResult.series.find(series =>
    isSeriesNameMatching(series.name, SisenseConstants.SERIES_ALTITUDE_AGL)
  );
  if (!!altitudeAGLSeries) {
    for (let altitude of altitudeAGLSeries.data) {
      if (!!altitude.y) {
        altitude.y = roundValue(convertMetresTo(altitude.y, FT_UNIT)[0]);
      }
    }
  }

  // Ground level is converted to a hardcoded unit (feet) for now
  const groundLevelSeries = queryResult.series.find(series =>
    isSeriesNameMatching(series.name, SisenseConstants.SERIES_GROUND_LEVEL)
  );
  if (!!groundLevelSeries) {
    for (let groundLevel of groundLevelSeries.data) {
      if (!!groundLevel.y) {
        groundLevel.y = roundValue(convertMetresTo(groundLevel.y, FT_UNIT)[0]);
      }
    }
  }
};

// Vertical Speed is converted to a hardcoded unit (ft/min) for now
export const convertSeriesVerticalSpeedUnit = (queryResult: WidgetQueryResult) => {
  const verticalSpeedSeries = queryResult.series.find(series =>
    isSeriesNameMatching(series.name, SisenseConstants.SERIES_VERTICAL_SPEED)
  );

  if (!!verticalSpeedSeries) {
    for (let verticalSpeed of verticalSpeedSeries.data) {
      if (!!verticalSpeed.y) {
        verticalSpeed.y = roundValue(formatSpeedFromMetersPerSeconds(verticalSpeed.y, FTPMIN_UNIT));
      }
    }
  }
};

// Ground Speed is converted to a hardcoded unit (knots) for now
export const convertSeriesGroundSpeedUnit = (queryResult: WidgetQueryResult) => {
  const groundSpeedSeries = queryResult.series.find(series =>
    isSeriesNameMatching(series.name, SisenseConstants.SERIES_GROUND_SPEED)
  );

  if (!!groundSpeedSeries) {
    for (let groundSpeed of groundSpeedSeries.data) {
      if (!!groundSpeed.y) {
        groundSpeed.y = roundValue(formatSpeedFromMetersPerSeconds(groundSpeed.y, KNOTS_UNIT));
      }
    }
  }
};

export const convertXAxisTimezone = (queryResult: WidgetQueryResult, userData: UserData) => {
  const xAxisCategories = queryResult.xAxis.categories;
  const convertedCategories: string[] = [];
  const dateFormat = `${userData.dateFormat.replace(/_/g, '/')} HH:mm:ss`;
  const formatFn = dateFormatter(dateFormat);
  for (let timeCategory of xAxisCategories) {
    const utcDate = moment.utc(timeCategory);
    convertedCategories.push(formatStandardDateStrings(formatFn, utcDate, userData.timezone)[0]);
  }
  queryResult.xAxis.categories = convertedCategories;
};

export const convertWidgetDataUnits = (queryResult: WidgetQueryResult, userData: UserData) => {
  convertSeriesSpeedUnit(queryResult, userData);
  convertSeriesAltitudeUnit(queryResult, userData);
  convertSeriesVerticalSpeedUnit(queryResult);
  convertSeriesGroundSpeedUnit(queryResult);
  convertXAxisTimezone(queryResult, userData);
};

export const convertWidgetSeriesLabels = (widgetMetadata: WidgetMetadata, userData: UserData) => {
  const valuesPanel = widgetMetadata.panel(SisenseConstants.PANEL_VALUES);
  if (!!valuesPanel) {
    const gpsSpeedItem = valuesPanel.items.find(
      item => item.jaql.title === SisenseConstants.SERIES_SPEED
    );
    if (!!gpsSpeedItem && !!userData.speedUnit) {
      gpsSpeedItem.jaql.title += ` (${userData.speedUnit})`;
    }

    const altitudeMSLItem = valuesPanel.items.find(
      item => item.jaql.title === SisenseConstants.SERIES_ALTITUDE_MSL
    );
    if (!!altitudeMSLItem && !!userData.altitudeUnit) {
      altitudeMSLItem.jaql.title += ` (${userData.altitudeUnit})`;
    }

    // For now, we convert vertical speed from m/s to ft/min regardless of user preference
    const verticalSpeedItem = valuesPanel.items.find(
      item => item.jaql.title === SisenseConstants.SERIES_VERTICAL_SPEED
    );
    if (!!verticalSpeedItem) {
      verticalSpeedItem.jaql.title += ` (${FTPMIN_UNIT})`;
    }

    // roll and pitch are already in degrees, so no need to convert
    const rollItem = valuesPanel.items.find(
      item => item.jaql.title === SisenseConstants.SERIES_ROLL
    );
    if (!!rollItem) {
      rollItem.jaql.title += ` (${DEGREES_UNIT})`;
    }

    // roll and pitch are already in degrees, so no need to convert
    const pitchItem = valuesPanel.items.find(
      item => item.jaql.title === SisenseConstants.SERIES_PITCH
    );
    if (!!pitchItem) {
      pitchItem.jaql.title += ` (${DEGREES_UNIT})`;
    }

    // For now, we convert ground speed to from m/s to knots regardless of user preference
    const groundSpeedItem = valuesPanel.items.find(
      item => item.jaql.title === SisenseConstants.SERIES_GROUND_SPEED
    );
    if (!!groundSpeedItem) {
      groundSpeedItem.jaql.title += ` (${KNOTS_UNIT})`;
    }

    // For now, we convert altitude AGL from m to ft regardless of user preference
    const altitudeAGLItem = valuesPanel.items.find(
      item => item.jaql.title === SisenseConstants.SERIES_ALTITUDE_AGL
    );
    if (!!altitudeAGLItem) {
      altitudeAGLItem.jaql.title += ` (${FT_UNIT})`;
    }

    // For now, we convert ground level from m to ft regardless of user preference
    const groundLevelItem = valuesPanel.items.find(
      item => item.jaql.title === SisenseConstants.SERIES_GROUND_LEVEL
    );
    if (!!groundLevelItem) {
      groundLevelItem.jaql.title += ` (${FT_UNIT})`;
    }
  }
};

const isParameterIridium = (parameter: string) =>
  isSeriesNameMatching(parameter, SisenseConstants.SERIES_ALTITUDE_MSL) ||
  isSeriesNameMatching(parameter, SisenseConstants.SERIES_SPEED);

export const hasAHRSParameterSelected = (left: string | undefined, right: string | undefined) => {
  if (left && right) {
    return !isParameterIridium(left) || !isParameterIridium(right);
  } else if (left) {
    return !isParameterIridium(left);
  } else {
    return false;
  }
};

export const setAGLAndGroundLevelAxisTitle = (
  domElement: WidgetDomObject,
  leftAxisSelection: string,
  rightAxisSelection: string
) => {
  const axisTitle =
    SisenseConstants.SERIES_ALTITUDE_AGL_GROUND_LEVEL + ` (${FT_UNIT})`;

  if (leftAxisSelection === SisenseConstants.SERIES_ALTITUDE_AGL_GROUND_LEVEL) {
    domElement.options.yAxis[0].title.text = axisTitle;
  } else if (rightAxisSelection === SisenseConstants.SERIES_ALTITUDE_AGL_GROUND_LEVEL) {
    domElement.options.yAxis[1].title.text = axisTitle;
  }
};
