export const buildHistoryUrl = (
  trackId: string,
  options?: {
    isReplayAvailable?: boolean;
    isFlightReportEnhancementsEnabled?: boolean;
  }
) => {
  const queryParameters = options?.isReplayAvailable ? '?3dfr' : '';
  return options?.isFlightReportEnhancementsEnabled
    ? `history/${trackId}/flight-explorer${queryParameters}`
    : `history/${trackId}/flight-report${queryParameters}`;
};
