import { WidgetMetadataPanel } from './sisenseTypes';
import { isSeriesNameMatching } from './widgetDataConversionUtils';
import { SisenseConstants } from './SisenseConstants';

/**
 * Each of the widget series items should be asssigned to one of the below chart axis positions.
 * LEFT_FIRST: first series in left axis
 * LEFT_SECOND: second series in left axis
 * RIGHT_FIRST: first series in right axis
 * RIGHT_SECOND: second series in right axis
 * NONE: series not to be shown in chart
 */
enum SeriesPositionInChartAxis {
  LEFT_FIRST,
  LEFT_SECOND,
  RIGHT_FIRST,
  RIGHT_SECOND,
  NONE
}

const getSeriesPositionInChartAxis = (
  seriesName: string,
  leftAxisSeriesItems: string[],
  rightAxisSeriesItems: string[]
): SeriesPositionInChartAxis => {
  for (let i = 0; i < leftAxisSeriesItems.length; i++) {
    const leftAxisSeriesItem = leftAxisSeriesItems[i];
    const seriesNameMatching = isSeriesNameMatching(seriesName, leftAxisSeriesItem);
    if (seriesNameMatching) {
      return i == 0 ? SeriesPositionInChartAxis.LEFT_FIRST : SeriesPositionInChartAxis.LEFT_SECOND;
    }
  }

  for (let i = 0; i < rightAxisSeriesItems.length; i++) {
    const rightAxisSeriesItem = rightAxisSeriesItems[i];
    const seriesNameMatching = isSeriesNameMatching(seriesName, rightAxisSeriesItem);
    if (seriesNameMatching) {
      return i == 0
        ? SeriesPositionInChartAxis.RIGHT_FIRST
        : SeriesPositionInChartAxis.RIGHT_SECOND;
    }
  }

  return SeriesPositionInChartAxis.NONE;
};

const getSeriesItemsInChartAxis = (selection: string | undefined) => {
  if (!!selection) {
    return SisenseConstants.SisenseConstantsMapping.get(selection) || [];
  }
  return [];
};

export const setSeries = (
  widgetMetadataPanel: WidgetMetadataPanel,
  leftSelection: string | undefined,
  rightSelection: string | undefined
) => {
  const leftAxisSeriesItems = getSeriesItemsInChartAxis(leftSelection);
  const rightAxisSeriesItems = getSeriesItemsInChartAxis(rightSelection);

  widgetMetadataPanel.items.forEach(item => {
    const seriesPositionInChartAxis = getSeriesPositionInChartAxis(
      item.jaql.title,
      leftAxisSeriesItems,
      rightAxisSeriesItems
    );

    switch (seriesPositionInChartAxis) {
      case SeriesPositionInChartAxis.LEFT_FIRST:
      case SeriesPositionInChartAxis.LEFT_SECOND:
        item.disabled = false;
        item.y2 = false;
        item.format.color.color =
          seriesPositionInChartAxis === SeriesPositionInChartAxis.LEFT_FIRST
            ? SisenseConstants.LEFT_COLOR
            : SisenseConstants.ALT_COLOR;
        break;
      case SeriesPositionInChartAxis.RIGHT_FIRST:
      case SeriesPositionInChartAxis.RIGHT_SECOND:
        item.disabled = false;
        item.y2 = true;
        item.format.color.color =
          seriesPositionInChartAxis === SeriesPositionInChartAxis.RIGHT_FIRST
            ? SisenseConstants.RIGHT_COLOR
            : SisenseConstants.ALT_COLOR;
        break;
      default:
        item.disabled = true;
        break;
    }
  });
};
