import { Amplify } from '@spidertracks/common';
import { ServiceAbstract } from '../../ServiceAbstract';
import { EventExplorerEvent } from '../../types/EventExplorerEvent';
import { EventSeverity, EventSubtype } from '../../../../../redux/types';

export interface EventsQueryParams {
  startTimestamp: number;
  endTimestamp: number;
  organisationIds: string[];
  aircraft: string[];
  eventSubtypes: EventSubtype[];
  eventSeverities: EventSeverity[];
}

export interface EventsExplorerEventsApiResponse {
  events: EventExplorerEvent[];
}

export class EventsExplorerService extends ServiceAbstract {
  private arrayToString = (array: string[]): string => {
    return (array || [])
      .filter(Boolean)
      .filter(value => value.trim() !== '')
      .join(',');
  };

  public async getEvents(queryParams: EventsQueryParams): Promise<EventsExplorerEventsApiResponse> {
    try {
      const queryStringParameters: Record<string, number | string> = {
        startIncluding: queryParams.startTimestamp,
        endExcluding: queryParams.endTimestamp,
        organisationIds: this.arrayToString(queryParams.organisationIds),
        aircraft: queryParams.aircraft.join(',')
      };

      if (queryParams.eventSubtypes.length) {
        queryStringParameters['eventSubtypes'] = this.arrayToString(queryParams.eventSubtypes);
      }
      if (queryParams.eventSeverities.length) {
        queryStringParameters['eventSeverities'] = this.arrayToString(queryParams.eventSeverities);
      }

      return await Amplify.API.get('api.spidertracks.io-authenticated', `events-explorer/events`, {
        queryStringParameters
      });
    } catch (error) {
      return {
        events: []
      };
    }
  }
}
