import React, { useCallback, useEffect, useRef, useState } from 'react';
import abbreviateNumber from '../../../../util/abbreviateNumber';
import { LoadingBackground } from '../../../LoadingBackground';
import DoubleSelectDropdown from '../components/DoubleSelectDropdown';
import { EmptyChartContainer } from './EmptyChartContainer';
import {
  filterData,
  filterOptions,
  getSourceFilter,
  isDashboardDimensionsMatchingFilters
} from './filters';
import { getSisenseEnv } from './getSisenseEnv';
import { setSeries } from './series';
import { SisenseConstants } from './SisenseConstants';
import useFlightReport from './useFlightReport';
import {
  convertWidgetDataUnits,
  convertWidgetSeriesLabels,
  hasAHRSParameterSelected,
  setAGLAndGroundLevelAxisTitle
} from './widgetDataConversionUtils';

const MAX_WAIT_SEC = 60;
const SCROLLER_WINDOW_SIZE_IN_MINS = 60;

const DEFAULT_DROPDOWN_OPTIONS = {
  [SisenseConstants.SERIES_ALTITUDE_MSL]: SisenseConstants.SERIES_ALTITUDE_MSL,
  [SisenseConstants.SERIES_SPEED]: SisenseConstants.SERIES_SPEED,
  [SisenseConstants.SERIES_VERTICAL_SPEED]: SisenseConstants.SERIES_VERTICAL_SPEED,
  [SisenseConstants.SERIES_ROLL]: SisenseConstants.SERIES_ROLL,
  [SisenseConstants.SERIES_PITCH]: SisenseConstants.SERIES_PITCH,
  [SisenseConstants.SERIES_GROUND_SPEED]: SisenseConstants.SERIES_GROUND_SPEED
};

let currentLeftSelection = SisenseConstants.SERIES_SPEED;
let currentRightSelection = SisenseConstants.SERIES_ALTITUDE_MSL;

export const FlightReportSummaryContainer = () => {
  const {
    selectedTrack,
    userData,
    isAHRSAllowed,
    enableAltitudeAglGroundLevel,
    trackMetadata,
    isXAxisLabelDisabled
  } = useFlightReport();

  const sisenseEnv = getSisenseEnv();
  const widgetDomRef = useRef();

  const [loading, setLoading] = useState(true);
  const [dataMissing, setDataMissing] = useState(false);
  const [widget, setWidget] = useState();
  const widgetRendered = useRef(false);

  const dropdownOptions = {
    ...DEFAULT_DROPDOWN_OPTIONS,
    ...(enableAltitudeAglGroundLevel && {
      [SisenseConstants.SERIES_ALTITUDE_AGL_GROUND_LEVEL]:
        SisenseConstants.SERIES_ALTITUDE_AGL_GROUND_LEVEL
    })
  };

  const disabledDropdownOptions = {
    ...(!trackMetadata?.ahrsParametersAvailable?.elevationM && {
      [SisenseConstants.SERIES_ALTITUDE_AGL_GROUND_LEVEL]:
        'To start generating this parameter for future flights, please configure at least one Safety Event'
    })
  };

  let previousLeftSelection = currentLeftSelection;
  let previousRightSelection = currentRightSelection;

  const widgetReady = widget => {
    if (widget.$noResults === true) {
      setDataMissing(true);
      setLoading(false);
    }

    widgetRendered.current = true;
  };

  const beforeQuery = (_, jaql) => {
    if (jaql && jaql.query) {
      jaql.query.count = 50000;
    }
  };

  const processResult = (widget, query) => {
    convertWidgetDataUnits(query.result, userData);
    query.result.series.forEach(series => {
      series.data.forEach(data => (data.marker.enabled = false));
    });

    // Attempt to set scroller location
    const firstSeries = query.result.series[0]?.data;
    if (!!firstSeries) {
      const startTime = Date.parse(firstSeries[0]['selectionData'][0]);

      const numPointsInWindow = firstSeries.filter(data => {
        const timeDifferenceInMinutes =
          (Date.parse(data['selectionData'][0]) - startTime) / (1000 * 60);
        return timeDifferenceInMinutes <= SCROLLER_WINDOW_SIZE_IN_MINS;
      }).length;

      widget.options.previousScrollerLocation.min = 0;
      widget.options.previousScrollerLocation.max = numPointsInWindow - 1;
    }
  };

  const beforeViewLoaded = (_, element) => {
    // Sisense uses highcharts under the hood, options can be seen here
    // https://api.highcharts.com/highcharts/tooltip
    element.options.tooltip = {
      ...element.options.tooltip,
      enabled: true,
      shared: true,
      useHTML: true,
      formatter: function() {
        const formattedPointsHtml = this.points
          .map(point => {
            return `
              <div class="cartesian-tooltip-seriesName">
                ${point.series.name}
              </div>
              <div class="cartesian-tooltip-singleLineValue" style="color: ${point.color}">
                <div class="cartesian-tooltip-value">
                  ${abbreviateNumber(point.y, 2)}
                </div>
              </div>`;
          })
          .join('');

        return `
          <div class="cartesian-tooltip">
            <div class="cartesian-tooltip-values">
              ${formattedPointsHtml}
            </div>
            <div class="highcharts-tooltip-line-separator" style="width: 100%"></div>
            <div class="cartesian-tooltip-xAxis">
              <div class="cartesian-tooltip-category">
                ${this.x}
              </div>
            </div>
          </div>`;
      }
    };

    element.options.legend.enabled = true;
    element.options.navigator.enabled = true;

    element.options.xAxis.labels.formatter = function() {
      return this.value.slice(10).trim();
    };

    // Disable x axis labels if feature flag enabled
    if (isXAxisLabelDisabled) {
      element.options.xAxis.labels.enabled = false;
    }

    if (element.options.yAxis) {
      element.options.yAxis.forEach(axis => {
        axis.gridLineWidth = 1;
      });
    }

    element.options.plotOptions.series.connectNulls = true;

    // Disable point interactivity (left / right clicks)
    element.options.plotOptions.series.point.events = {
      contextmenu: undefined,
      click: undefined
    };

    // Update legend order to match y axis
    element.options.series.forEach(series => (series.legendIndex = series.yAxis));

    setAGLAndGroundLevelAxisTitle(element, currentLeftSelection, currentRightSelection);
  };

  const beforeTooltip = (_, context) => {
    context.cancel = true;
  };

  const dashboardRefreshed = dashboard => {
    if (!isDashboardDimensionsMatchingFilters(dashboard)) {
      setDataMissing(true);
    }
  };

  const renderDashboard = useCallback(() => {
    if (
      selectedTrack &&
      selectedTrack.spider &&
      selectedTrack.spider.serialNumber &&
      selectedTrack.flightId
    ) {
      const Sisense = window.Sisense;
      if (!!Sisense) {
        Sisense.connect(sisenseEnv.endpoint)
          .then(app => {
            app.dashboards
              .load(sisenseEnv.dashboardId)
              .then(dashboard => {
                const widget = dashboard.widgets.get(sisenseEnv.widgetId);
                convertWidgetSeriesLabels(widget.metadata, userData);
                setWidget(widget);

                widget.on('processresult', processResult);
                widget.on('beforeviewloaded', beforeViewLoaded);
                widget.on('beforedatapointtooltip', beforeTooltip);
                widget.on('beforequery', beforeQuery);
                widget.on('ready', widgetReady);

                widget.container = widgetDomRef.current;

                const spider = selectedTrack.spider.serialNumber.toUpperCase();
                const powerCycle = selectedTrack.flightId;

                // Default view(two Iridium parameters) we dont show AHRS data
                filterData(dashboard, spider, powerCycle, false);
                setLoading(false);

                dashboard.on('refreshend', dashboardRefreshed);
                dashboard.refresh();
              })
              .catch(e => {
                console.error(e);
              });
          })
          .catch(e => {
            console.error(e);
          });
      } else {
        console.error('Sisense not loaded.');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTrack, sisenseEnv]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (widgetRendered.current === false) {
        setDataMissing(true);
      }
    }, MAX_WAIT_SEC * 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    renderDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConfirm = async () => {
    if (
      currentLeftSelection === previousLeftSelection &&
      currentRightSelection === previousRightSelection
    ) {
      return;
    }

    const valuePanel = widget.metadata.panel('values');
    let items = valuePanel.items;
    if (!items || !items.length) {
      return;
    }

    setSeries(valuePanel, currentLeftSelection, currentRightSelection);

    const hasAHRSParameters = hasAHRSParameterSelected(currentLeftSelection, currentRightSelection);
    const sourceFilter = getSourceFilter(isAHRSAllowed && hasAHRSParameters);

    // Let dashboard do the refresh as we might need to
    // filter out ahrs data
    widget.dashboard.$$model.filters.update(sourceFilter, filterOptions);

    previousLeftSelection = currentLeftSelection;
    previousRightSelection = currentRightSelection;
  };

  const onDropdownSelectionChanged = async ({ left, right }) => {
    currentLeftSelection = left;
    currentRightSelection = right;
  };

  return (
    <>
      {widget && !dataMissing && isAHRSAllowed && (
        <DoubleSelectDropdown
          dataSource={dropdownOptions}
          onChange={onDropdownSelectionChanged}
          onConfirm={onConfirm}
          onCancel={onConfirm}
          defaultLeft={SisenseConstants.SERIES_SPEED}
          defaultRight={SisenseConstants.SERIES_ALTITUDE_MSL}
          disabledOptions={disabledDropdownOptions}
        />
      )}
      <div id="flight-report-summary-container" className="fill-parent">
        {dataMissing ? (
          <EmptyChartContainer />
        ) : (
          <div className={`fill-parent ${dataMissing ? 'hidden' : ''}`} ref={widgetDomRef}></div>
        )}

        {loading && (
          <div className="overlay-loading d-flex flex-1">
            <LoadingBackground />
          </div>
        )}
      </div>
    </>
  );
};

export default FlightReportSummaryContainer;
