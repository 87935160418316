// Modified from https://stackoverflow.com/a/2686098
const abbreviateNumber = (number: number, decimalPlaces: number): string => {
  // 2 decimal places => 100, 3 => 1000, etc
  const multiplier = Math.pow(10, decimalPlaces);

  // Enumerate number abbreviations
  const abbrev = ['k', 'm', 'b', 't'];

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    const size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by multiplier, round, and then divide by multiplier.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * multiplier) / size) / multiplier;

      // Handle special case where we round up to the next abbreviation
      if (number == 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      // Add the letter for the abbreviation
      return `${number}${abbrev[i]}`;
    }
  }

  return number.toFixed(decimalPlaces).toString();
};

export default abbreviateNumber;
