import Dashboards from '@highcharts/dashboards';
import { EventLocation } from '../getData';

const isValidCoordinate = (coordinate: number, isLongitude: boolean): boolean => {
  return isLongitude
    ? coordinate >= -180 && coordinate <= 180
    : coordinate >= -90 && coordinate <= 90;
};

export const getDashboardOptions = (events: EventLocation[]) => {
  const validEvents = events.filter(
    e => isValidCoordinate(e.lon, true) && isValidCoordinate(e.lat, false) && e.name.trim() !== ''
  );

  const multipointGeometry = {
    type: 'MultiPoint',
    coordinates: validEvents.map(e => [e.lon, e.lat])
  };

  const mapView =
    validEvents.length > 0
      ? { fitToGeometry: multipointGeometry, padding: 20 }
      : { center: [0, 0], zoom: 2 };

  const options: Dashboards.Board.Options = {
    dataPool: {
      connectors: [
        {
          id: 'events-data',
          type: 'JSON',
          options: {
            data: []
          }
        }
      ]
    },
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: 'dashboard-events-data'
                }
              ]
            }
          ]
        }
      ]
    },
    components: [
      {
        id: 'world-map',
        renderTo: 'dashboard-events-data',
        title: 'Events',
        type: 'Highcharts',
        chartConstructor: 'mapChart',
        chartOptions: {
          chart: {
            styledMode: true
          },
          series: [
            {
              type: 'tiledwebmap',
              name: 'TWM Tiles',
              provider: {
                type: 'OpenStreetMap',
                theme: 'Standard'
              }
            },
            {
              type: 'mappoint',
              enableMouseTracking: true,
              accessibility: {
                point: {
                  descriptionFormat:
                    '{#if isCluster}' +
                    'Grouping of {clusterPointsAmount} events.' +
                    '{else}' +
                    '{name}' +
                    '{/if}'
                }
              },
              colorKey: 'clusterPointsAmount',
              name: 'Events',
              data: validEvents,
              color: 'blue',
              marker: {
                lineWidth: 1,
                lineColor: '#fff',
                symbol: 'mapmarker',
                radius: 8
              },
              tooltip: {
                headerFormat: '',
                pointFormat: '{point.name}',
                clusterFormat: 'Clustered events: {point.clusterPointsAmount}'
              },
              dataLabels: {
                verticalAlign: 'top'
              }
            }
          ],
          plotOptions: {
            mappoint: {
              cluster: {
                enabled: true,
                allowOverlap: false,
                animation: {
                  duration: 450
                },
                layoutAlgorithm: {
                  type: 'grid',
                  gridSize: 70
                },
                zones: [
                  {
                    from: 1,
                    to: 4,
                    marker: {
                      radius: 13
                    }
                  },
                  {
                    from: 5,
                    to: 9,
                    marker: {
                      radius: 15
                    }
                  },
                  {
                    from: 10,
                    to: 15,
                    marker: {
                      radius: 17
                    }
                  },
                  {
                    from: 16,
                    to: 20,
                    marker: {
                      radius: 19
                    }
                  },
                  {
                    from: 21,
                    to: 100,
                    marker: {
                      radius: 21
                    }
                  }
                ]
              }
            }
          },
          mapNavigation: {
            enabled: true,
            buttonOptions: {
              alignTo: 'spacingBox'
            }
          },
          legend: {
            enabled: false
          },
          mapView,
          title: {
            text: void 0
          }
        },
        sync: {
          highlight: true,
          visibility: true
        }
      }
    ]
  };

  return options;
};
