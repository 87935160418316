import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { Collapse, Typography, Row } from 'antd';
import InfoWindowRow from '../InfoWindowRow';
import {
  formatStandardDateStrings,
  fromISO8601,
  dateFormatter
} from '../../../../../helpers/formatTime';
const { Panel } = Collapse;
const { Title } = Typography;

// as per https://developer.baronweather.com/products/aviation-products/#notams there are some valid non Iso8601 strings in the end time param
const notamNonISOEndTimes = {
  WIE: 'With Immediate Effect',
  UFN: 'Until Further Notice',
  PERM: 'Permanent'
};

export const getNoticeTimes = (dateFormat, timezone) => notice => {
  let localIssuetime;
  let utcIssuetime;
  let localStartTime;
  let utcStartTime;
  let localEndTime;
  let utcEndTime;

  let localMoment = fromISO8601(notice.issuetime);

  const formatFn = dateFormatter(dateFormat);

  if (notice.issuetime) {
    [localIssuetime, utcIssuetime] = formatStandardDateStrings(formatFn, localMoment, timezone);
  } else {
    localIssuetime = notice.raw_text.includes('CANCELLED') ? 'Cancelled' : 'Data Not Available';
  }

  if (notice.valid_begin) {
    [localStartTime, utcStartTime] = formatStandardDateStrings(
      formatFn,
      fromISO8601(notice.valid_begin),
      timezone
    );
  }

  if (Object.keys(notamNonISOEndTimes).includes(notice.valid_end)) {
    localEndTime = notamNonISOEndTimes[notice.valid_end];
  } else {
    if (notice.valid_end) {
      [localEndTime, utcEndTime] = formatStandardDateStrings(
        formatFn,
        fromISO8601(notice.valid_end),
        timezone
      );
    }
  }

  return [localIssuetime, utcIssuetime, localStartTime, utcStartTime, localEndTime, utcEndTime];
};

export const NotamMarker = ({ notam, dateFormat, timezone }) => {
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
  const [markerInstance, setMarkerInstance] = useState();

  const getFormattedNoticeTimes = getNoticeTimes(dateFormat, timezone);

  const onMarkerClick = () => {
    setIsInfoWindowOpen(true);
  };

  return (
    <Fragment>
      <Marker
        key={notam.id}
        icon={{
          scale: 5,
          fillColor: '#FF766A',
          fillOpacity: 1,
          path: window.google.maps.SymbolPath.CIRCLE,
          strokeColor: '#FF766A',
          strokeOpacity: 0.4,
          strokeWeight: 6
        }}
        onClick={onMarkerClick}
        onLoad={setMarkerInstance}
        position={{ lat: notam.station.coordinates[1], lng: notam.station.coordinates[0] }}
      />
      {markerInstance && isInfoWindowOpen && (
        <InfoWindow
          anchor={markerInstance}
          onCloseClick={() => setIsInfoWindowOpen(false)}
          zIndex={0}
          options={{ maxWidth: '450' }}
        >
          <Fragment>
            <Title level={4}>{notam.station.name}</Title>
            <div className="text-left">
              <Collapse accordion bordered={false} defaultActiveKey={[notam.notams[0].id]}>
                {notam.notams.map(notice => {
                  const [
                    localIssuetime,
                    utcIssuetime,
                    localStartTime,
                    utcStartTime,
                    localEndTime,
                    utcEndTime
                  ] = getFormattedNoticeTimes(notice);

                  return (
                    <Panel header={localIssuetime} key={notice.id}>
                      <InfoWindowRow title="Issued" content={localIssuetime} />
                      <InfoWindowRow content={utcIssuetime} />
                      {notice.valid_begin && (
                        <Fragment>
                          <InfoWindowRow title="Start" content={`${localStartTime}`} />
                          <InfoWindowRow content={`${utcStartTime}`} />
                        </Fragment>
                      )}
                      {notice.valid_end && (
                        <Fragment>
                          <InfoWindowRow title="End" content={`${localEndTime}`} />
                          {utcEndTime && <InfoWindowRow content={`${utcEndTime}`} />}
                        </Fragment>
                      )}
                      <hr />
                      {notice.raw_text && <Row>{notice.raw_text}</Row>}
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
          </Fragment>
        </InfoWindow>
      )}
    </Fragment>
  );
};

NotamMarker.propTypes = {
  notam: PropTypes.object,
  dateFormat: PropTypes.string,
  timezone: PropTypes.string
};

NotamMarker.displayName = 'NotamMarker';

export default NotamMarker;
