import { SDK } from '../SDK';
import { PublicAircraftData } from '../types/AircraftData';
import { PublicTrackData } from '../types/TrackData';
import { HistoryTableTracksAPIResponse, TracksAPIResponse } from '../types/TracksAPIResponse';
import { PublicOrganisationAPIResponse } from '../types/PublicOrganisationAPIResponse';
import { KMLData } from '../types/KMLData';
import { UserData, UserPreference } from '../types/UserData';
import { HTTPError } from '../types/HTTPError';
import { UnknownOrganisationError } from '../types/UnknownOrganisationError';
import { TRACK_WITH_EVENTS } from '../../../../components/Flying/Map/GoogleMap/constants';
import { LatestTracksService } from './services/LatestTracksService';
import { TrackHistoryRequest } from '../types/TrackHistoryRequest';
import { FeatureFlagsAPIResponse } from '../types/FeatureFlagsAPIResponse';
import { Person } from '../types/Person';
import { PersonAssociatedTag } from '../types/PersonAssociatedTag';
import { SavePersonAssociatedTagParams } from '../types/SavePersonAssociatedTagParams';
import { KMLAPIResponse } from '../types/KMLAPIResponse';
import { TrackTotalsResponse } from '../types/TrackTotalsResponse';

export class PublicAPI extends SDK {
  protected isPublic: boolean = true;

  protected pathSegments = {
    AIRCRAFT: '/',
    TRACKS: '/orgs/<ORGANISATION_ID>/public-flying?fetchEvents=true',
    TRACKS_V2: '/',
    KML: '/orgs/<ORGANISATION_ID>/public-kmls',
    KML_STATE: '/',
    USER: '/',
    USER_PREFERENCES: '/',
    ORGANISATION: '/orgs/public/<ORGANISATION_SLUG>',
    ORG_AIRCRAFT: '/',
    FEATURES: '/features',
    PERSONS: '/',
    MEMBERS: '/',
    USER_TAGS: '/'
  };

  private getOrganisationRequest(organisationSlug: string): Request {
    return new Request(
      this.buildUrl(this.getPath('ORGANISATION').replace('<ORGANISATION_SLUG>', organisationSlug))
    );
  }

  private async getOrganisationId(organisationSlug: string): Promise<string> {
    const organisationInfo = await this.call<PublicOrganisationAPIResponse>(
      this.getOrganisationRequest(organisationSlug)
    );
    return organisationInfo.id;
  }

  private async getTracksRequest(organisationSlug: string): Promise<Request> {
    try {
      const organisationId = await this.getOrganisationId(organisationSlug);

      return new Request(
        this.buildUrl(this.getPath('TRACKS').replace('<ORGANISATION_ID>', organisationId))
      );
    } catch (error) {
      if (error instanceof HTTPError && error.httpResponse.status === 404) {
        throw new UnknownOrganisationError(organisationSlug, error.httpResponse);
      }
      throw error;
    }
  }

  public async getAircraft(organisationSlug: string): Promise<PublicAircraftData[]> {
    try {
      const trackData = await this.call<TracksAPIResponse<PublicTrackData>>(
        await this.getTracksRequest(organisationSlug)
      );
      return trackData.items.map((track: any) => track.aircraft);
    } catch (error) {
      if (this.convertErrorToEvent(error)) {
        return [];
      } else {
        throw error;
      }
    }
  }

  public async getLatestTracks(organisationSlug: string): Promise<PublicTrackData[]> {
    try {
      const trackData = await this.call<TracksAPIResponse<PublicTrackData>>(
        await this.getTracksRequest(organisationSlug)
      );
      return trackData.items;
    } catch (error) {
      if (this.convertErrorToEvent(error)) {
        return [];
      } else {
        throw error;
      }
    }
  }

  public getTrackHistory(
    params: TrackHistoryRequest | null
  ): Promise<TracksAPIResponse<PublicTrackData[]>> {
    throw new Error('Method not implemented.');
  }
  public getHistoryTableTracks(
    params: URLSearchParams | null
  ): Promise<HistoryTableTracksAPIResponse<PublicTrackData[]>> {
    throw new Error('Method not implemented.');
  }

  public getHistoryTableTracksCSV(params: URLSearchParams): Promise<void> {
    throw new Error('Method not implemented.');
  }

  public getHistoryTableTracksTotals(params: URLSearchParams): Promise<TrackTotalsResponse> {
    throw new Error('Method not implemented.');
  }

  public getLatestTracksService(): LatestTracksService {
    if (!this.serviceInstances['LatestTracksService']) {
      this.serviceInstances['LatestTracksService'] = new LatestTracksService(
        window.env.api.endpoint
      );
    }

    return this.serviceInstances['LatestTracksService'] as LatestTracksService;
  }

  public async getKML(organisationSlug: string): Promise<KMLData[]> {
    if (!organisationSlug) {
      return [];
    }
    const organisationId = await this.getOrganisationId(organisationSlug);
    let request = new Request(
      this.buildUrl(this.getPath('KML').replace('<ORGANISATION_ID>', organisationId)),
      {}
    );
    const kmlData = await this.call<KMLAPIResponse>(request);

    return kmlData.items.map((item): KMLData => ({ ...item, type: 'kml' }));
  }

  public async getUsers(): Promise<UserData> {
    return {
      dateFormat: 'DD_MM_YYYY',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // default to browser Timezone

      //Empty Defaults
      altitudeUnit: 'ft',
      city: '',
      countryCode: '',
      createdDate: '',
      displayName: '',
      distanceUnit: 'Nautical Miles',
      email: '',
      firstName: ',',
      flyingPagePromptShown: true,
      gender: null,
      hideMapRadiusShading: true,
      id: '',
      lastName: '',
      latLongFormat: 'degreesminutesseconds',
      orgs: [],
      phones: [],
      speedUnit: 'knots',
      spiderTxtEnabled: false,
      spiderTxtId: 0,
      states: {
        mapLayer: 'MAP',
        showAllPoints: true,
        groupTrackPoints: true,
        pointGroupingOption: TRACK_WITH_EVENTS,
        sidebarTab: 'ALPHABETICAL',
        sidebarVisible: true,
        tcVersion: '',
        trackLineBold: true,
        trackLineGrayUnderline: true,
        trackLineMatchAircraftColour: true,
      },
      timeOffset: 0
    } as UserData;
  }

  public async getPersons(orgId: string): Promise<Person[]> {
    // Not Implemented, no Persons for Public
    return [];
  }
  public async getPersonAssociatedTags(orgId: string): Promise<PersonAssociatedTag[]> {
    // Not Implemented, no Tags for Public
    return [];
  }

  public async savePersonAssociatedTag(tag: SavePersonAssociatedTagParams): Promise<any> {
    // Not Implemented, no Tags for Public
  }

  public async deletePersonAssociatedTag(tagId: number): Promise<any> {
    // Not Implemented, no Tags for Public
  }

  public async saveUserPreference<K extends keyof UserPreference, V extends UserPreference[K]>(
    preferenceKey: K,
    preferenceValue: V
  ): Promise<void> {
    // No-op implementation. Public SDK need not save user preferences.
  }

  public async downloadTracks(downloadTracksRequest: any): Promise<void> {
    // Not Implemented, no Downloads for Public API
  }

  public async getFeatureFlags(): Promise<FeatureFlagsAPIResponse> {
    return this.getDefaultFeatureFlags();
  }

  public getFavouritesService(): any {}

  public getSpiderSettingsService(): any {}

  public getGeofencesService(): any {}

  public getAircraftService(): any {}

  public getIntegrationService(): any {}

  public getFlightDataService(): any {}

  public getFlightData3DFRService(): any {}

  public getSisenseService(): any {}

  public getFlightEventConfigurationsService(): any {}

  public getNotificationConfigurationService(): any {}

  public getEventRulesService(): any {}

  public getEventService(): any {}

  public getEventsExplorerService(): any {}

  public getFeatureConfigService(): any {}

  public getMembers(orgId: string): any {}

  public getOrgAircraft(organisationSlug: string): any {}
}
