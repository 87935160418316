import React from 'react';
export const EmptyChartContainer = () => {
  return (
    <div className="align-center">
      <h3>This chart is not available for this flight.</h3>
      <br />
      If this is a recent flight, the data may be currently under processing. <br />
      Please check back again later.
    </div>
  );
};

export default EmptyChartContainer;
