//LATER: fix eslint parse error: https://stackoverflow.com/questions/62079477/line-0-parsing-error-cannot-read-property-map-of-undefined
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { Moment } from 'moment';

import './styles.scss';

import {
  DateFilter,
  getRanges,
  GroupedEnumFilter,
  OrgsFilter,
  AircraftFilter
} from '../common/filters';
import {
  getDates,
  setDates,
  getEventSubtypes,
  setEventSubTypes,
  getOrganisations,
  setOrganisations,
  getAircraft,
  setAircraft,
  getEventSeverities,
  setEventSeverities
} from '../../redux/slice/eventsExplorer';
import { EventSeverity, EventSubtype } from '../../redux/types';

import { EventsExplorerDashboard } from './Dashboard/EventsExplorerDashboard';
import getEventsData, { EventLocation } from './getData';

export function splitEnumToGroups<T>(
  enumObject: {
    [key: string]: T;
  },
  acronyms: RegExp[] = []
): { [key: string]: { title: string; value: T }[] } {
  return Object.keys(enumObject).reduce<{ [key: string]: { title: string; value: T }[] }>(
    (acc, key) => {
      const [group, ...values] = key.split('__');
      if (!group) {
        return acc;
      }
      const value = values.join('__');
      const groupName =
        group[0].toUpperCase() +
        group
          .slice(1)
          .replace(/_/g, ' ')
          .toLowerCase();

      return {
        ...acc,
        [groupName]: [
          ...(acc[groupName] || []),
          {
            value,
            title: acronyms.reduce<string>(
              (acc, regex) => acc.replace(regex, match => match.toUpperCase()),
              value[0].toUpperCase() +
                value
                  .replace(/_/g, ' ')
                  .slice(1)
                  .toLowerCase()
            )
          }
        ]
      } as { [key: string]: { title: string; value: T }[] };
    },
    {}
  );
}

const EventsExplorer = () => {
  const dispatch = useDispatch();

  const dates = useSelector(getDates);
  const setDateFilter = useCallback(
    (dates: [Moment, Moment]) => {
      dispatch(setDates({ startTime: dates[0], endTime: dates[1] }));
    },
    [dispatch, setDates]
  );

  const organisations = useSelector(getOrganisations);
  const applyOrganisations = useCallback(
    (orgIds: string[]) => {
      dispatch(setOrganisations(orgIds));
    },
    [setOrganisations]
  );

  const selectedAircraft = useSelector(getAircraft);
  const applyAircraft = useCallback(
    (aircraft: string[]) => {
      dispatch(setAircraft(aircraft));
    },
    [dispatch, setAircraft]
  );

  const eventSubtypes = useSelector(getEventSubtypes);
  const selectEventSubtypes = useCallback(
    selected => {
      dispatch(setEventSubTypes(selected));
    },
    [dispatch, setEventSubTypes]
  );
  const eventSubtypesGroups = splitEnumToGroups(EventSubtype, [/AGL/gi, /\bG\b/gi, /AMSL/gi]);

  const eventSeverities = useSelector(getEventSeverities);
  const applyEventSeverities = useCallback(
    selected => {
      dispatch(setEventSeverities(selected));
    },
    [dispatch, setEventSeverities]
  );

  const severitiesGroups = splitEnumToGroups(EventSeverity);

  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState(() => ({
    startTimestamp: dates.startTime?.valueOf(),
    endTimestamp: dates.endTime?.valueOf(),
    organisationIds: organisations,
    aircraft: selectedAircraft,
    eventSubtypes,
    eventSeverities
  }));

  const [applyMarker, setApplyMarker] = useState(false);
  const apply = () => {
    setFilters({
      startTimestamp: dates.startTime?.valueOf(),
      endTimestamp: dates.endTime?.valueOf(),
      organisationIds: organisations,
      aircraft: selectedAircraft,
      eventSubtypes,
      eventSeverities
    });

    setLoading(true);
    setApplyMarker(!applyMarker);
  };

  const [data, setData] = useState<EventLocation[]>([]);

  useEffect(() => {
    setLoading(true);
    getEventsData(filters)
      .then(data => {
        setData(data);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [filters, applyMarker]);

  return (
    <div className="eventsExplorerPage">
      <div data-testid="controls-panel" className="filterPanelContainer">
        <DateFilter
          ranges={getRanges()}
          calendarFieldsValues={[dates.startTime ?? undefined, dates.endTime ?? undefined]}
          timezone={'Pacific/Auckland'}
          dateFormat={'YYYY-MM-DD'}
          handleChange={setDateFilter}
          disabled={loading}
        />
        <OrgsFilter
          selectedOrgs={organisations}
          setSelectedOrgs={applyOrganisations}
          disabled={loading}
        />
        <AircraftFilter
          selectedAircraft={selectedAircraft}
          setSelectedAircraft={applyAircraft}
          orgsToFilterBy={organisations}
          disabled={loading}
        />
        <GroupedEnumFilter<EventSubtype>
          title="Event Types"
          placeholder={['Event Types', 'Event Type']}
          groupMap={eventSubtypesGroups}
          value={eventSubtypes}
          setValue={selectEventSubtypes}
          disabled={loading}
        />
        <GroupedEnumFilter<EventSeverity>
          title="Severity"
          placeholder={['Severities', 'Severity']}
          groupMap={severitiesGroups}
          value={eventSeverities}
          setValue={applyEventSeverities}
          disabled={loading}
        />
        <Button
          data-testid="apply-button"
          loading={loading}
          disabled={!(organisations.length && selectedAircraft.length)}
          onClick={apply}
          title={
            organisations.length
              ? selectedAircraft.length
                ? 'Apply filters'
                : 'Please select at least one Aircraft'
              : 'Please select at least one Organisation'
          }
        >
          Apply
        </Button>
      </div>
      <div data-testid="charts-panel" className="chartsPanelContainer">
        <div style={{ overflow: 'auto', height: '500vh' }}>
          <EventsExplorerDashboard data={data} />
        </div>
      </div>
    </div>
  );
};

export default EventsExplorer;
