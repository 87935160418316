export interface Model {
  disabled: boolean;
  jaql: {
    column: string;
    dim: string;
    isDashboardFilter: boolean;
  };
}

export interface Filters {
  $$items: Model[];
}

export interface Jaql {
  datatype: string;
  dim: string;
  filter: {
    multiSelection: boolean;
    members?: string | number;
    all?: boolean;
    explicit: boolean;
  };
  title: string;
}

export interface FilterUpdateSearch {
  jaql: Jaql;
}

export interface FilterUpdateOptions {
  save: boolean;
  refresh: boolean;
}

export interface Dashboard {
  filters: Filters;
  $$model: {
    filters: {
      update: (search: FilterUpdateSearch, options: FilterUpdateOptions) => void;
    };
  };
}

export interface WidgetSeriesData {
  y: number | null;
}

export interface WidgetSeries {
  name: string;
  data: WidgetSeriesData[];
}

export interface WidgetQueryResult {
  series: WidgetSeries[];
  xAxis: {
    categories: string[];
  };
}

export interface WidgetMetadataPanelItem {
  jaql: {
    title: string;
    type: string;
  },
  disabled: boolean;
  y2?: boolean;
  format: {
    color: {
      color: string;
      type: string;
    }
  }
}

export interface WidgetMetadataPanel {
  items: WidgetMetadataPanelItem[];
}

export interface WidgetMetadata {
  panel(name: string): WidgetMetadataPanel | undefined;
}

export enum SourceType {
  All = 'All',
  AHRS = 'AHRS',
  Go = 'GO'
}

export interface WidgetDomObject {
  options: {
    xAxis: {
      categories: string[],
      title: {
        enabled: boolean,
        text: string | undefined
      }
    },
    yAxis: [
      {
        title: {
          enabled: boolean,
          text: string
        }
      },
      {
        title: {
          enabled: boolean,
          text: string
        }
      }
    ]
  }
}
