import { Form, Row } from 'antd';
import { GroupSelectionOption, MultiSelectForm, collapseGroups } from '@spidertracks/components';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { FormComponentProps } from 'antd/lib/form';
import React, { FC, useEffect, useState } from 'react';

export interface EventRule {
  id: string;
  label: string;
  toolTipLabel?: string;
}

interface EventRuleSelectorProps extends FormComponentProps {
  eventClassLabel: string;
  eventClassLabelSingular: string;
  eventClassLabelPlural: string;
  eventRuleOptions: CheckboxOptionType[];
  showAllChoice?: boolean;
}

export const EventRuleSelector: FC<EventRuleSelectorProps> = ({
  form,
  eventClassLabel,
  eventClassLabelSingular,
  eventClassLabelPlural,
  eventRuleOptions,
  showAllChoice
}) => {
  const groups: GroupSelectionOption[] = [];
  if (showAllChoice) {
    groups.push({
      label: `All ${eventClassLabelPlural}`,
      id: '*',
      onSelectFilter: (value: CheckboxValueType) => true,
      isSelectAll: true
    });
  }

  const groupMapping = new Map<string, string>(
    groups.map((value: GroupSelectionOption) => [value.id, value.label])
  );

  const closedLabel = collapseGroups(
    groupMapping,
    eventClassLabelSingular,
    eventClassLabelPlural,
    `Select ${eventClassLabelPlural}`,
    true
  )(form.getFieldValue('eventRules'));

  return (
    <Form.Item label={eventClassLabel} style={{ textAlign: 'left' }} className="mt-3">
      <Row>
        {form.getFieldDecorator('eventRules', {
          initialValue: [],
          rules: [
            {
              required: true,
              message: `Please select ${eventClassLabelPlural}`
            }
          ]
        })(
          <MultiSelectForm
            closedLabel={closedLabel}
            fieldName="eventRules"
            form={form}
            options={eventRuleOptions}
            showSearch
            shifted={true}
            groups={groups}
            displayName={eventClassLabel}
          />
        )}
      </Row>
    </Form.Item>
  );
};

export default EventRuleSelector;
