import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { LoadingBackground } from '../LoadingBackground';

const Flight3dPlayerContainer = React.lazy(() => import('./Flight3dPlayerContainer'));

export default function FlightPlayer(props) {
  return (
    <Suspense fallback={<LoadingBackground />}>
      <Flight3dPlayerContainer {...props} />
    </Suspense>
  );
}

FlightPlayer.propTypes = {
  trackId: PropTypes.string.isRequired,
  stId: PropTypes.string.isRequired,
  aircraftType: PropTypes.string.isRequired,
  aircraftModel: PropTypes.string,
  orgId: PropTypes.string.isRequired,
  flags: PropTypes.shape({
    useNewFlightDataService: PropTypes.bool
  }).isRequired
};
