import * as moment from 'moment';
import { helpers } from '@spidertracks/flight-3d-player';
import { AHRS, FlightData, Position } from './types/FlightData';

const getModelURL = (isFixedWing: boolean, aircraftModel: string | null) => {
  const models = new Map([['AW09', './helicopter_AS350.gltf']]);
  if (aircraftModel && models.has(aircraftModel)) {
    return models.get(aircraftModel);
  }

  return isFixedWing ? './Cessna_172.gltf' : './helicopter_AS350.gltf';
};

function formatUnixEpoch(from: number) {
  return moment
    .unix(from)
    .utc()
    .format('YYYY-MM-DD\\THH:mm:ss.SSS\\Z');
}

export async function getCZML(
  data: FlightData,
  aircraftType: string,
  aircraftModel: string | null,
  terrainProvider: any,
  include: boolean = false
) {
  const { trackEndTime, position, ahrs, meta } = data;

  if (!position || !position.length || !ahrs || !ahrs.length) {
    return;
  }

  let offsetAHRS = ahrs;
  if (meta && meta.offsets) {
    const yawOffset = meta.offsets['yaw-forward-speed-avg']
      ? parseFloat(meta.offsets['yaw-forward-speed-avg'])
      : parseFloat(meta.offsets['yaw-forward-speed']);
    if (yawOffset) {
      offsetAHRS = ahrs.map(p => ({ ...p, yaw: p.yaw + yawOffset }));
    }
  }
  const isFixedWing = aircraftType.toLowerCase().startsWith('fixed');

  const { gps: adjPosition, ahrs: adjAHRS } = await helpers.lockAircraftToGround(
    position,
    offsetAHRS,
    terrainProvider,
    isFixedWing,
    include
  );

  const postOrientation: (Position | AHRS)[] = [...adjPosition, ...adjAHRS].sort((a, b) =>
    a.timestamp < b.timestamp ? -1 : 1
  );
  const begin = formatUnixEpoch(adjPosition[0].timestamp);
  const end = formatUnixEpoch(trackEndTime);
  const result = postOrientation.reduce(
    (accum: any, val: Position | AHRS) => {
      if ((<Position>val).latitude) {
        const { timestamp, latitude, longitude, altitude } = <Position>val;

        //contains gps data
        return {
          ...accum,
          position: [...accum.position, formatUnixEpoch(timestamp), longitude, latitude, altitude],
          lastGPS: { timestamp, latitude, longitude, altitude }
        };
      } else if ((<AHRS>val).yaw) {
        const { timestamp, pitch, roll, yaw } = <AHRS>val;

        //is an ahrs point
        let { lastGPS } = accum;

        if (!lastGPS) {
          return accum;
        }
        const { x, y, z, w } = helpers.computeQuaternionForOrientation(
          lastGPS.longitude,
          lastGPS.latitude,
          lastGPS.altitude,
          yaw,
          roll * -1,
          pitch
        );

        return {
          ...accum,
          orientation: [...accum.orientation, formatUnixEpoch(timestamp), x, y, z, w]
        };
      } else {
        return accum;
      }
    },
    {
      position: [],
      orientation: []
    }
  );

  const modelURL = getModelURL(isFixedWing, aircraftModel);

  return [
    {
      id: 'document',
      name: 'CZML Path',
      version: '1.0',
      clock: include
        ? {
            interval: `${begin}/${end}`,
            currentTime: begin,
            multiplier: 1
          }
        : undefined
    },
    {
      id: 'path',
      availability: include ? `${begin}/${end}` : undefined,
      path: {
        material: {
          polygon: {
            color: {
              rgba: [255, 0, 255, 255]
            }
          }
        },
        width: 2,
        leadTime: 1,
        resolution: 1
      },
      model: include
        ? {
            id: 'airplane',
            gltf: modelURL,
            scale: 1,
            minimumPixelSize: 28,
            nodeTransformations: {
              RootNode: {
                rotation: {
                  unitQuaternion: [0, 0.7071, 0, 0.7071]
                }
              }
            }
          }
        : undefined,
      viewFrom: include
        ? {
            cartesian: [70, 20, 30]
          }
        : undefined,
      position: {
        interpolationAlgorithm: 'LAGRANGE',
        interpolationDegree: 1,
        cartographicDegrees: result.position
      },
      orientation: {
        unitQuaternion: result.orientation
      }
    }
  ];
}
