import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import { FullState } from '../../store';
import { EventsExplorerState } from '../types';

const now = moment();
const aMonthAgo = now.clone().add(-30, 'days');

const initialState: EventsExplorerState = {
  dateFilter: {
    startTime: aMonthAgo,
    endTime: now
  },
  organisations: [],
  aircraft: [],
  eventSubtypes: [],
  eventSeverities: []
};

const eventsExplorerSlice = createSlice({
  name: 'eventsExplorer',
  initialState,
  reducers: {
    setDates: (
      state: EventsExplorerState,
      { payload }: { payload: EventsExplorerState['dateFilter'] }
    ) => {
      state.dateFilter = payload;
      return state;
    },
    resetDates: (state: EventsExplorerState) => {
      state.dateFilter.startTime = aMonthAgo;
      state.dateFilter.endTime = now;
      return state;
    },

    setOrganisations: (
      state: EventsExplorerState,
      { payload }: { payload: EventsExplorerState['organisations'] }
    ) => {
      state.organisations = payload;
      return state;
    },

    setAircraft: (
      state: EventsExplorerState,
      { payload }: { payload: EventsExplorerState['aircraft'] }
    ) => {
      state.aircraft = payload;
      return state;
    },

    setEventSubTypes: (
      state: EventsExplorerState,
      { payload }: { payload: EventsExplorerState['eventSubtypes'] }
    ) => {
      state.eventSubtypes = payload;
      return state;
    },

    setEventSeverities: (
      state: EventsExplorerState,
      { payload }: { payload: EventsExplorerState['eventSeverities'] }
    ) => {
      state.eventSeverities = payload;
      return state;
    }
  }
});

// Actions:
export const {
  setDates,
  resetDates,
  setOrganisations,
  setAircraft,
  setEventSubTypes,
  setEventSeverities
} = eventsExplorerSlice.actions;

// Selectors:
export const getDates = (state: FullState) => state.eventsExplorer.dateFilter;
export const getOrganisations = (state: FullState) => state.eventsExplorer.organisations;
export const getAircraft = (state: FullState) => state.eventsExplorer.aircraft;
export const getEventSubtypes = (state: FullState) => state.eventsExplorer.eventSubtypes;
export const getEventSeverities = (state: FullState) => state.eventsExplorer.eventSeverities;

export default eventsExplorerSlice;
