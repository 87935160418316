import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { getInstance } from '../../../common/api/spidertracks-sdk';
import EventsConfigTable from './EventsConfigTable';
import EventsConfigDrawer from './EventsConfigDrawer';
import EventsConfigHeader from './EventsConfigHeader';
import { useDispatch } from 'react-redux';
import { fetchAircraft } from '../../../redux/slice/aircraft';
import { getCheckedEventTypes } from './getCheckedEventTypes';
import { getTableData, getEventTypeFilters, sortEventTypes } from './utils';
import { eventRuleCreateSuccess, eventRuleCreateError } from '../../../helpers';
import { hasSpiderType } from '../../../helpers';
import { useFeatureFlag } from '../../../hooks';

export const EventsContainer = () => {
  const { organisationId } = useParams();
  const SpidertracksSDK = getInstance();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const dispatch = useDispatch();
  const [eventTypes, setEventTypes] = useState([]);
  const [eventParameters, setEventParameters] = useState([]);
  const [aircraft, setAircraft] = useState([]);
  const [eventRules, setEventRules] = useState([]);
  const insightsSafetyEnabled = useFeatureFlag('insights-safety', [organisationId]);
  const betaInsightEventRules = useFeatureFlag('beta-insight-event-rules', [organisationId]);

  useEffect(() => {
    dispatch(fetchAircraft());
  }, [dispatch]);

  const fetchConfigurations = useCallback(async () => {
    setIsLoading(true);
    try {
      const service = SpidertracksSDK.getEventRulesService();
      const [eventTypes, eventRules, eventParameters, spiders] = await Promise.all([
        service.getAllEventTypes(organisationId),
        service.getAllEventRules(organisationId),
        service.getAllEventParameters('fsi'),
        SpidertracksSDK.getSpiderSettingsService().getSpiders()
      ]);
      const filteredEventRules = eventRules.filter(
        eventRule =>
          (eventRule.severityDisplayThresholds && insightsSafetyEnabled) ||
          eventRule.displayThresholds !== undefined
      );
      const aircraftHaveRules = filteredEventRules.reduce((acc, rule) => {
        const newIds = rule.aircraftIds
          ? rule.aircraftIds.reduce((acc, id) => ({ ...acc, [id]: true }), {})
          : {};
        return {
          ...acc,
          ...newIds
        };
      }, {});
      const aircraft = await SpidertracksSDK.getOrgAircraft(organisationId);
      const eligibleAircraft = aircraft.filter(
        craft => hasSpiderType(['SX'])(spiders)(craft) || aircraftHaveRules[craft.id]
      );
      eligibleAircraft.forEach(craft => {
        if (!hasSpiderType(['SX'])(spiders)(craft)) {
          craft.unassigned = true;
        }
      });

      const tableData = getTableData(filteredEventRules, eventParameters);
      const checkedEventTypes = getCheckedEventTypes(
        eventTypes,
        filteredEventRules,
        eligibleAircraft
      );
      let sortedEventTypes = sortEventTypes(checkedEventTypes);
      if (!betaInsightEventRules) {
        sortedEventTypes = sortedEventTypes.filter(
          eventType => eventType.eventType !== 'excessive_g_force'
        );
      }

      setEventParameters(eventParameters);
      setTableData(tableData);
      setEventTypes(sortedEventTypes);
      setEventRules(filteredEventRules);
      setAircraft(eligibleAircraft);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [SpidertracksSDK, organisationId]);

  const createEventRule = async eventRule => {
    try {
      await SpidertracksSDK.getEventRulesService().createEventRule(organisationId, eventRule);
      eventRuleCreateSuccess();
    } catch (e) {
      eventRuleCreateError();
    } finally {
      fetchConfigurations();
    }
  };

  useEffect(() => {
    fetchConfigurations();
  }, [organisationId, fetchConfigurations]);

  const openDrawer = () => {
    setDrawerVisibility(true);
  };

  const closeDrawer = () => {
    setDrawerVisibility(false);
  };

  return (
    <React.Fragment>
      <div className="px-5 py-4" style={{ overflow: 'scroll', height: '100%' }}>
        <EventsConfigHeader
          disabled={!eventTypes.length || !aircraft.length}
          openDrawer={openDrawer}
        />
        <EventsConfigTable
          aircraft={aircraft}
          tableData={tableData}
          eventRules={eventRules}
          eventTypeFilters={getEventTypeFilters(tableData)}
          isLoading={isLoading}
          eventParameters={eventParameters}
          eventTypes={eventTypes}
          refresh={fetchConfigurations}
          insightsSafetyEnabled={insightsSafetyEnabled}
        />
        <EventsConfigDrawer
          aircraft={aircraft}
          visibility={drawerVisibility}
          onClose={closeDrawer}
          eventTypes={eventTypes}
          eventParameters={eventParameters}
          eventRules={eventRules}
          save={createEventRule}
          insightsSafetyEnabled={insightsSafetyEnabled}
        />
      </div>
    </React.Fragment>
  );
};

export default EventsContainer;
