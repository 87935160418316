import { Dashboard, SourceType } from './sisenseTypes';
import { SisenseConstants } from './SisenseConstants';

export const filterOptions = {
  save: false,
  refresh: true
};

export const getSourceFilter = (isAHRSAllowed: boolean) => ({
  jaql: {
    datatype: 'text',
    dim: SisenseConstants.DIMENSION_SOURCE,
    filter: {
      multiSelection: true,
      explicit: !isAHRSAllowed,
      ...(isAHRSAllowed ? { all: true } : { members: SourceType.Go })
    },
    title: 'source'
  }
});

export const filterData = (
  dashboard: Dashboard,
  spider: string,
  powerCycle: number,
  isAHRSAllowed: boolean
) => {
  const spiderFilter = {
    jaql: {
      datatype: 'text',
      dim: SisenseConstants.DIMENSION_SPIDER,
      filter: {
        multiSelection: true,
        members: spider,
        explicit: true
      },
      title: 'spider'
    }
  };
  dashboard.$$model.filters.update(spiderFilter, filterOptions);

  const powerCycleFilter = {
    jaql: {
      datatype: 'numeric',
      dim: SisenseConstants.DIMENSION_POWER_CYCLE,
      filter: {
        multiSelection: true,
        members: powerCycle,
        explicit: true
      },
      title: 'power_cycle'
    }
  };
  dashboard.$$model.filters.update(powerCycleFilter, filterOptions);

  const sourceFilter = getSourceFilter(isAHRSAllowed);
  dashboard.$$model.filters.update(sourceFilter, filterOptions);
};

// For now remove the checks for number of dimensions
// so it will work for both test and prod regardless of different sisense
// dashboard settings.
// TODO: Add length check and source check back once
// this filter has been added to sisense prod
export const isDashboardDimensionsMatchingFilters = (dashboard: Dashboard) => {
  if (!!dashboard) {
    const dashboardFilters = dashboard.filters;
    const dashboardDimensions = dashboardFilters.$$items
      .filter(filter => !filter.disabled && filter.jaql.isDashboardFilter)
      .map(filter => filter.jaql.dim);

    return (
      dashboardDimensions.includes(SisenseConstants.DIMENSION_SPIDER) &&
      dashboardDimensions.includes(SisenseConstants.DIMENSION_POWER_CYCLE)
    );
  }
  return false;
};
