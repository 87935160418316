import React from 'react';
import PropTypes from 'prop-types';
import { FlightEventTitle, FlightEventTimeline } from './components';
import { dateFormatter, formatStandardDateStrings, fromTimestamp } from 'helpers/formatTime';
import { getSpinner } from '../../../../util';
import { formatDisplayPoint } from './utils';

import './styles/scss/styles.scss';

export const FlightReportEventContainer = ({
  displayPoints,
  selectedTrack,
  timezone,
  dateFormat,
  className,
  showTitle = true
}) => {
  if (!selectedTrack) {
    return <div className="event-panel">{getSpinner()}</div>;
  }

  const formatDateTimeFn = dateFormatter(`${dateFormat} | HH:mm:ss`);
  const formatTimefn = dateFormatter('HH:mm:ss');

  const [localDepartedDateTime] = formatStandardDateStrings(
    formatDateTimeFn,
    fromTimestamp(selectedTrack.departedTime),
    timezone
  );

  const eventData = formatDisplayPoint(displayPoints, formatTimefn, timezone);

  return (
    <div className={`event-panel ${className}`}>
      {showTitle && (
        <FlightEventTitle
          flightId={selectedTrack.flightId}
          aircraftDepartedTime={localDepartedDateTime}
          aircraftRegistration={selectedTrack.aircraft.registration}
        />
      )}
      <FlightEventTimeline timelineEvents={eventData} />
    </div>
  );
};

FlightReportEventContainer.propTypes = {
  dateFormat: PropTypes.string,
  displayPoints: PropTypes.array,
  selectedTrack: PropTypes.object,
  timezone: PropTypes.string,
  className: PropTypes.string,
  showTitle: PropTypes.bool
};

export default FlightReportEventContainer;
