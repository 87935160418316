import React, { FC, Fragment, useEffect, useState } from 'react';
import { LoadingBackground } from '../../LoadingBackground';
import {
  getSisenseSession,
  hasSisenseSession,
  insertSisenseScriptElement
} from '../../../helpers/sisense';
import { flightReportChartError } from '../../../helpers/globalNotifications';

// Wrap a child component in this to ensure the presense of window.Sisense prior to rendering
// children. This is required for the Sisense.JS approach to embedding widgets. See:
//
//   https://sisense.dev/guides/embedding/sisense.js.html#sso-and-sisensejs
//
// Note that it is not sufficient to simply assume that if `sisense.js` is present in the DOM, we
// have a valid Sisense session. Unfortunately we still need to explicitly check, otherwise we'll
// see odd redirects as sisense.js tries and fails to retrieve resources from
// spidertracks.sisense.com.
export const RequireSisenseJS: FC = ({ children }) => {
  const [ready, setReady] = useState(false);

  const executeSSOFlow = async () => {
    try {
      const hasSession = await hasSisenseSession();
      if (!hasSession) {
        await getSisenseSession();
      }

      insertSisenseScriptElement(setReady);
    } catch (e) {
      console.error(`Sisense auth failure: ${e.message}`);
      flightReportChartError();
    }
  };

  useEffect(() => {
    executeSSOFlow();
  }, []);

  return (
    <Fragment>
      <div id="sisenseApp" data-comment="Sisense dashboard container" style={{ display: 'none' }} />
      {ready ? children : <LoadingBackground />}
    </Fragment>
  );
};

export default RequireSisenseJS;
