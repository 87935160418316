import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import {
  dateFormatter,
  formatStandardDateStrings,
  fromTimestamp,
  getDuration
} from '../../../../helpers/formatTime';
import { abbreviateDistanceUnit, convertMetresTo } from '../utils/helper';
import styled from 'styled-components';
import { Collapse } from 'antd';
import { FlightReportPanel, FlightEventTimeline } from './components';
import './styles/scss/styles.scss';
import {
  PrivateTrackData,
  DisplayPoint
} from '../../../../common/api/spidertracks-sdk/types/TrackData';
import { formatDisplayPoint } from './utils';
import { setSelectedTrackId } from '../../../../redux/slice/tracks';
import { setSelectedDisplayPoint } from '../../../../redux/slice/events';

const { Panel } = Collapse;

const FlightReportWrapper = styled.div`
  width: 400px;
  z-index: 1;
  background: #fff;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
`;

const BackButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  align-self: start;
  margin: 1rem 1rem 0 1rem;
`;

const ReportPanel = styled(Panel)`
  &&& {
    background-color: #fff;
    width: 100%;
    :not(:last-child) {
      border-radius: 0;
      border-bottom: 2px solid rgb(222, 222, 222) !important;
    }
    .ant-collapse-content {
      border-top: none !important;
    }
  }
`;

interface FlightReportMultipleEventContainerProps {
  tracks: PrivateTrackData[];
  displayPoints: { [key: number | string]: DisplayPoint[] };
  timezone: string;
  dateFormat: string;
  distanceUnit: string;
  setSelectedTrackId: Function;
  setSelectedDisplayPoint: Function;
  selectedTrackId: string;
}

const FlightReportMultipleEventContainer = ({
  tracks,
  displayPoints,
  timezone,
  dateFormat,
  distanceUnit,
  setSelectedTrackId,
  setSelectedDisplayPoint,
  selectedTrackId
}: FlightReportMultipleEventContainerProps) => {
  const formatDateTimeFn = dateFormatter(`${dateFormat} | HH:mm:ss`);
  const formatTimefn = dateFormatter('HH:mm:ss');
  const history = useHistory();

  const flights = useMemo(
    () =>
      tracks
        .sort((a, b) => b.departedTime - a.departedTime)
        .map(track => {
          let [departedDateTime] = formatStandardDateStrings(
            formatDateTimeFn,
            fromTimestamp(track.departedTime),
            timezone
          );

          const [convertedDistance, unit] = convertMetresTo(track.totalDistance, distanceUnit);
          const active = selectedTrackId === track.trackId.toString();

          return (
            <ReportPanel
              key={track.trackId}
              showArrow={false}
              header={
                <FlightReportPanel
                  key={track.trackId}
                  registration={track.aircraft.registration}
                  departedTime={departedDateTime}
                  distance={`${convertedDistance} ${abbreviateDistanceUnit(unit)}`}
                  duration={getDuration(track.departedTime, track.endTime).formatted}
                  active={active}
                />
              }
            >
              <FlightEventTimeline
                timelineEvents={formatDisplayPoint(
                  displayPoints[track.trackId],
                  formatTimefn,
                  timezone
                )}
              />
            </ReportPanel>
          );
        }),
    [distanceUnit, formatDateTimeFn, timezone, tracks, displayPoints, formatTimefn, selectedTrackId]
  );

  return (
    <div className="d-flex flex-direction-col">
      <FlightReportWrapper className="shadow">
        <BackButtonWrapper>
          <i onClick={() => history.goBack()} className="material-icons event-go-back-icon">
            arrow_back_ios
          </i>
          <h2>{`${tracks.length} Flight${tracks.length > 1 ? 's' : ''} selected`}</h2>
        </BackButtonWrapper>
        <Collapse
          activeKey={selectedTrackId}
          onChange={key => {
            const id = key as string;
            setSelectedTrackId(id);
            setSelectedDisplayPoint(id ? displayPoints[id][displayPoints[id].length - 1] : null);
          }}
          accordion
          style={{ padding: 0, margin: 0, border: 0, width: '100%' }}
        >
          {flights}
        </Collapse>
      </FlightReportWrapper>
    </div>
  );
};

const mapDispatchToProps = {
  setSelectedTrackId,
  setSelectedDisplayPoint
};

export default connect(null, mapDispatchToProps)(FlightReportMultipleEventContainer);
