// Make sure that there is at least one default selected member for each parameter group. This is so that Highcharts requires at least one series in left axis.
import {
  convertMetresPerSecondTo,
  convertMetresTo,
  convertRadiansToDegrees
} from '../../../Flying/Map/utils/helper';
import { DisplayPoint } from '../../../../common/api/spidertracks-sdk/types/TrackData';
import { UserData } from '../../../../redux/types';
import { FlightDataSample } from '../../../../types/FlightData';

const DP = 2;

export interface Parameter {
  key: string;
  name: string;
  displayName: string;
  tooltip?: string;
  prerequisites?: {
    AHRS?: boolean;
    featureFlag?: string;
    flightDataSample?: (sample: FlightDataSample) => boolean;
  };
  accessors: {
    flightDataSample?: (sample: FlightDataSample, userData: UserData) => number;
    displayPoint?: (sample: DisplayPoint, userData: UserData) => number;
  };
}

export interface ParameterGroup {
  key: string;
  name: string;
  members: Parameter[];
  unit: (userData: UserData) => string;
  defaultSelectedMembers: string[];
  featureFlag?: string;
  axisDisplayString: (userData: UserData) => string;
}

export const AvailableChartParameters: ParameterGroup[] = [
  {
    key: 'altitude',
    name: 'Altitude',
    defaultSelectedMembers: ['AMSL'],
    axisDisplayString: (userData: UserData) => `Altitude (${userData.altitudeUnit})`,
    unit: (userData: UserData) => userData.altitudeUnit,
    members: [
      {
        key: 'amsl',
        name: 'AMSL',
        displayName: 'Altitude AMSL',
        tooltip: 'Height Above Mean Sea Level',
        accessors: {
          flightDataSample: (sample, userData) =>
            convertMetresTo(sample.altitudeMetres, userData.altitudeUnit, DP)[0],
          displayPoint: (sample: DisplayPoint, userData: UserData) => {
            if (sample.altitudeUnit === 'm') {
              return convertMetresTo(sample.altitude, userData.altitudeUnit, DP)[0];
            } else {
              return parseFloat(sample.altitude.toFixed(DP));
            }
          }
        }
      },
      {
        key: 'agl',
        name: 'AGL',
        displayName: 'Altitude AGL',
        tooltip: 'Height Above Ground Level',
        prerequisites: {
          flightDataSample: (sample: FlightDataSample) => true
        },
        accessors: {
          flightDataSample: (sample, userData) =>
            convertMetresTo(sample.aglMetres, userData.altitudeUnit, DP)[0]
        }
      },
      {
        key: 'terrain',
        name: 'Terrain',
        displayName: 'Terrain',
        prerequisites: {
          flightDataSample: (sample: FlightDataSample) => true
        },
        accessors: {
          flightDataSample: (sample, userData) =>
            convertMetresTo(sample.elevationMetres, userData.altitudeUnit, DP)[0]
        }
      }
    ]
  },
  {
    key: 'speed',
    name: 'Speed',
    defaultSelectedMembers: ['Ground Speed'],
    axisDisplayString: (userData: UserData) => `Speed (${userData.speedUnit})`,
    unit: (userData: UserData) => userData.speedUnit,
    members: [
      {
        key: 'groundSpeed',
        name: 'Ground Speed',
        displayName: 'Ground Speed',
        tooltip: 'From GPS',
        accessors: {
          flightDataSample: (sample, userData) =>
            convertMetresPerSecondTo(sample.groundSpeedMetresPerSecond, userData.speedUnit, DP),
          displayPoint: (sample: DisplayPoint, userData: UserData) => {
            if (sample.speed_raw !== undefined) {
              return convertMetresPerSecondTo(sample.speed_raw.value, userData.speedUnit, DP);
            }
            return convertMetresPerSecondTo(sample.speed, userData.speedUnit, DP);
          }
        }
      }
    ]
  },
  {
    key: 'vertical_speed',
    name: 'Vertical Speed',
    defaultSelectedMembers: ['Vertical Speed'],
    axisDisplayString: (userData: UserData) => `Vertical Speed (ft/min)`,
    unit: (userData: UserData) => 'ft/min',
    members: [
      {
        key: 'verticalSpeed',
        name: 'Vertical Speed',
        displayName: 'Vertical Speed',
        tooltip: 'From GPS',
        prerequisites: {
          AHRS: true,
          flightDataSample: (sample: FlightDataSample) =>
            !(sample.velocity?.downMetresPerSecond === undefined)
        },
        accessors: {
          flightDataSample: (sample, userData) =>
            convertMetresPerSecondTo(sample.velocity.downMetresPerSecond * -1.0, 'ft/min', DP) // Make it so that up is +ve)
        }
      }
    ]
  },
  {
    key: 'attitude',
    name: 'Attitude',
    defaultSelectedMembers: ['Pitch'],
    axisDisplayString: (userData: UserData) => `Attitude (degrees)`,
    unit: (userData: UserData) => 'degrees',
    members: [
      {
        key: 'pitch',
        name: 'Pitch',
        displayName: 'Pitch',
        prerequisites: {
          AHRS: true,
          flightDataSample: (sample: FlightDataSample) => true
        },
        accessors: {
          flightDataSample: (sample, userData) => convertRadiansToDegrees(sample.pitchRadians)
        }
      },
      {
        key: 'roll',
        name: 'Roll',
        displayName: 'Roll',
        prerequisites: {
          AHRS: true,
          flightDataSample: (sample: FlightDataSample) => true
        },
        accessors: {
          flightDataSample: (sample, userData) => convertRadiansToDegrees(sample.rollRadians)
        }
      }
    ]
  },
  {
    key: 'forceG',
    name: 'Force',
    defaultSelectedMembers: ['G Force'],
    featureFlag: 'enable-peak-g',
    axisDisplayString: (userData: UserData) => `Force (G)`,
    unit: (userData: UserData) => 'G',
    members: [
      {
        key: 'gForce',
        name: 'G Force',
        displayName: 'G Force',
        tooltip: 'From AHRS, units: g',
        prerequisites: {
          AHRS: true,
          flightDataSample: (sample: FlightDataSample) => !(sample.gForce === undefined)
        },
        accessors: {
          flightDataSample: (sample, userData) => parseFloat(sample.gForce.toFixed(DP))
        }
      }
    ]
  }
];
