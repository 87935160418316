/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Tooltip } from 'antd';

export const withToolTip = (Component: Function) => {
  return function ComponentWithToolTip(props: any) {
    return (
      <Tooltip placement="rightTop" title={props.toolTip}>
        {Component(props)}
      </Tooltip>
    );
  };
};
