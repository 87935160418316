const SERIES_SPEED = 'GPS Speed';
const SERIES_ALTITUDE_MSL = 'Altitude MSL';
const SERIES_ALTITUDE_AGL = 'Altitude AGL';
const SERIES_GROUND_LEVEL = 'Ground Level';
const SERIES_VERTICAL_SPEED = 'Vertical Speed';
const SERIES_ROLL = 'Roll';
const SERIES_PITCH = 'Pitch';
const SERIES_GROUND_SPEED = 'Ground Speed';
const SERIES_ALTITUDE_AGL_GROUND_LEVEL = 'Altitude AGL & Ground Level';
const DIMENSION_SPIDER = '[points.spider]';
const DIMENSION_POWER_CYCLE = '[points.power_cycle]';
const DIMENSION_SOURCE = '[points.source]';
const PANEL_VALUES = 'values';
const LEFT_COLOR = '#A0D911';
const RIGHT_COLOR = '#1890FF';
const ALT_COLOR = '#002766';
const GREY_COLOR = '#8C8C8C';

const SisenseConstantsMapping = new Map([
  [SERIES_SPEED, [SERIES_SPEED]],
  [SERIES_ALTITUDE_MSL, [SERIES_ALTITUDE_MSL]],
  [SERIES_VERTICAL_SPEED, [SERIES_VERTICAL_SPEED]],
  [SERIES_ROLL, [SERIES_ROLL]],
  [SERIES_PITCH, [SERIES_PITCH]],
  [SERIES_GROUND_SPEED, [SERIES_GROUND_SPEED]],
  [SERIES_ALTITUDE_AGL_GROUND_LEVEL, ['Altitude AGL', 'Ground Level']]
]);

const SisenseConstants = {
  SisenseConstantsMapping,
  SERIES_SPEED,
  SERIES_ALTITUDE_MSL,
  SERIES_ALTITUDE_AGL,
  SERIES_GROUND_LEVEL,
  SERIES_VERTICAL_SPEED,
  SERIES_ROLL,
  SERIES_PITCH,
  SERIES_GROUND_SPEED,
  SERIES_ALTITUDE_AGL_GROUND_LEVEL,
  DIMENSION_SPIDER,
  DIMENSION_POWER_CYCLE,
  DIMENSION_SOURCE,
  PANEL_VALUES,
  LEFT_COLOR,
  RIGHT_COLOR,
  ALT_COLOR,
  GREY_COLOR
};

export { SisenseConstants };
