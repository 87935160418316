import { getConfig } from '../../../../common/getConfig';

export const getSisenseEnv = () => {
  const config = getConfig();

  const dashboards = config.sisense.dashboards;
  const dashboard = dashboards.find(dashboard => dashboard.name === 'flight-summary');

  if (!dashboard || !dashboard.widgets) {
    throw new Error('Could not find sisense Dashboard');
  }

  const widget = dashboard.widgets.find(widget => widget.name === 'flight-parameters');

  if (!widget) {
    throw new Error('Could not find sisense Widget in config');
  }

  return {
    sisenseEndpoint: config.sisense.endpoint,
    dashboardId: dashboard.id,
    widgetId: widget.id
  };
};
