import { SolidTooltip } from '@spidertracks/components';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import React from 'react';
import {
  EventParameter,
  EventParameterItem,
  EventRule,
  EventRuleBase,
  EventType,
  SeverityThresholds,
  Thresholds
} from '../../../common/api/spidertracks-sdk/private/services/EventRulesService';
import { Parameter } from '../../../common/api/spidertracks-sdk/private/services/FlightEventConfigurationsService';
import { AircraftBase, AircraftDetails } from '../../../types/aircraft';

export type KeyedEventParameter = Record<string, EventParameter[]>;
export const transformParameters = (eventParameters: EventParameterItem[]) =>
  eventParameters.reduce<KeyedEventParameter>(
    (acc, param) => ({ ...acc, [param.eventType]: param.parameters }),
    {}
  );

export const getOperation = (op: string) => (op === 'gt' ? 'greater than' : 'less than');
export const getThreshold = (eventRule: EventRule, name: string) => {
  if (eventRule.severityDisplayThresholds) {
    const severityDisplayThresholdValue = eventRule.severityDisplayThresholds[name];
    if (typeof severityDisplayThresholdValue === 'number') {
      return severityDisplayThresholdValue;
    }

    return `<span style="color:green">${severityDisplayThresholdValue.low}</span>/<span style="color:orange">${severityDisplayThresholdValue.medium}</span>/<span style="color:red">${severityDisplayThresholdValue.high}</span>`;
  }

  return eventRule.displayThresholds[name];
};

export const getDescription = (eventParameters: EventParameter[], rule: EventRule) => {
  return eventParameters
    .reduce<string[]>((acc, p) => {
      const operation = getOperation(p.op);
      const threshold = getThreshold(rule, p.name);
      return [...acc, `${p.displayName} ${operation} ${threshold} ${p.displayUnit}`];
    }, [])
    .join(' & ');
};

export type EventConfigTableDataItem = EventRuleBase & {
  parameters: any;
  description: string;
};
export type EventConfigTableData = EventConfigTableDataItem[];

export type TableData = {
  allAircraft?: boolean;
  aircraftIds?: string[];
  description: string;
  eventClass: string;
  eventType: string;
  eventTypeDisplayName: string;
  id: string;
  organisationId: string;
  parameters: Parameter[];
} & (
  | {
      thresholds: Thresholds;
      displayThresholds: Thresholds;
    }
  | {
      severityThresholds: SeverityThresholds;
      severityDisplayThresholds: SeverityThresholds;
    }
);

export const getTableData = (
  eventRules: EventRule[],
  eventParams: EventParameterItem[]
): TableData[] => {
  const transformedParameters = transformParameters(eventParams);
  return eventRules.reduce<EventConfigTableData>((tableData, rule) => {
    const parameters = transformedParameters[rule.eventType].map(p => {
      return {
        ...p,
        eventTypeDisplayName: rule.eventTypeDisplayName,
        displayValue: rule.severityThresholds
          ? rule.severityDisplayThresholds[p.name]
          : rule.displayThresholds[p.name]
      };
    });
    const description = getDescription(parameters, rule);

    tableData.push({
      ...rule,
      parameters,
      description
    });
    return tableData;
  }, []);
};

interface FilterItem {
  text: string;
  value: string;
}

export const getEventTypeFilters = (tableData: EventConfigTableData = []) =>
  tableData.reduce<FilterItem[]>(
    (acc, val) => [
      ...acc,
      {
        key: val.eventType,
        text: val.eventTypeDisplayName,
        value: val.eventType
      }
    ],
    []
  );

export const getMessage = (minDisplayValue: string | number, maxDisplayValue: string | number) =>
  `Must be between ${minDisplayValue} and ${maxDisplayValue}`;

export const checkValidProps = (minDisplayValue: number, maxDisplayValue: number, type: string) => {
  return (
    type === 'numeric' && typeof minDisplayValue === 'number' && typeof maxDisplayValue === 'number'
  );
};

export const getCustomValidationRules = ({
  minDisplayValue,
  maxDisplayValue
}: Pick<EventParameter, 'minDisplayValue' | 'maxDisplayValue'>) => [
  {
    type: 'number',
    message: getMessage(minDisplayValue, maxDisplayValue),
    min: minDisplayValue,
    max: maxDisplayValue,
    required: true
  }
];

const defaultValidationRules = [
  {
    pattern: /^[-]?([1-9]\d*\.?|0\.)([1-9]|\d[1-9]|\d\d[1-9]|\d\d\d[1-9])?$/,
    message: 'Please enter valid values',
    required: true
  }
];

export const getValidationRules = ({ minDisplayValue, maxDisplayValue, type }: EventParameter) =>
  checkValidProps(minDisplayValue, maxDisplayValue, type)
    ? getCustomValidationRules({ minDisplayValue, maxDisplayValue })
    : defaultValidationRules;

export const validateParameter = (eventParameter: EventParameter) =>
  ['displayName', 'op'].every(p => p in eventParameter);

export const getThresholdInputLabel = (eventParameter: EventParameter) => {
  if (!validateParameter(eventParameter)) {
    return `[Data Error: Please Contact Support]`;
  }
  return `${eventParameter.displayName} is ${getOperation(eventParameter.op)}`;
};

type ExtendedEventType = EventType & {
  ruleAlreadyConfigured?: boolean;
  description: string;
};

export const sortDescriptionsByAZ = (a: ExtendedEventType, b: ExtendedEventType): number => {
  const descriptionA = a.description.toUpperCase();
  const descriptionB = b.description.toUpperCase();

  if (descriptionA < descriptionB) {
    return -1;
  }
  if (descriptionA > descriptionB) {
    return 1;
  }
  return 0;
};

export const sortEventTypesByAZ = (a: ExtendedEventType, b: ExtendedEventType): number => {
  const eventTypeLabelA = a.eventTypeDisplayName.toUpperCase();
  const eventTypeLabelB = b.eventTypeDisplayName.toUpperCase();

  if (eventTypeLabelA < eventTypeLabelB) {
    return -1;
  }
  if (eventTypeLabelA > eventTypeLabelB) {
    return 1;
  }
  return 0;
};

export const sortEventTypesByEnabled = (a: ExtendedEventType, b: ExtendedEventType): number => {
  if (b.ruleAlreadyConfigured === true && a.ruleAlreadyConfigured === false) {
    return -1;
  }
  if (b.ruleAlreadyConfigured === false && a.ruleAlreadyConfigured === true) {
    return 1;
  }
  return 0;
};

export const sortEventTypesByNew = (a: ExtendedEventType, b: ExtendedEventType): number => {
  if (a.newEventType && !b.newEventType) {
    return -1;
  }
  if (!a.newEventType && b.newEventType) {
    return 1;
  }
  return 0;
};

export const sortEventTypes = (eventTypes: ExtendedEventType[]): ExtendedEventType[] => {
  return eventTypes
    .filter(type => {
      return type.ruleAlreadyConfigured === false;
    })
    .sort(sortEventTypesByAZ)
    .sort(sortEventTypesByNew)
    .concat(
      eventTypes
        .filter(type => {
          return type.ruleAlreadyConfigured === true;
        })
        .sort(sortEventTypesByAZ)
    );
};

export const byRegistration = <T extends AircraftBase>(a: T, b: T) =>
  a.registration < b.registration ? -1 : a.registration > b.registration ? 1 : 0;

export const checkboxOptionTransformer = (
  disabled = false,
  valueTransformer: (aircraft: AircraftDetails) => string = aircraft => aircraft.id
) => <T extends AircraftDetails>(aircraft: T): CheckboxOptionType => ({
  disabled,
  label: disabled ? (
    <SolidTooltip
      title={
        aircraft.unassigned
          ? 'This aircraft currently has no device which supports events.'
          : 'This aircraft is already configured for a different event.'
      }
      trigger="click"
    >
      {aircraft.registration}
    </SolidTooltip>
  ) : (
    aircraft.registration
  ),
  value: valueTransformer(aircraft)
});

export const findRuleForEventType = (eventType: string) => (rules: EventRule[]) => (
  aircraft: AircraftDetails
) => {
  const eventTypeRules = rules.filter(r => r.eventType === eventType);
  return (
    eventTypeRules.find(r => r.allAircraft) ||
    eventTypeRules.find(rule => rule.aircraftIds && rule.aircraftIds.includes(aircraft.id))
  );
};

export const buildAircraftOptions = (
  aircraft: AircraftDetails[],
  existingRules: EventRule[],
  eventType: string,
  editRuleId?: string
) => {
  const available = [];
  const disabled = [];
  const alreadyConfigured = findRuleForEventType(eventType)(existingRules);

  for (const a of aircraft) {
    const rule = alreadyConfigured(a);
    if (a.unassigned && !rule) {
      disabled.push(a);
    } else {
      !rule || rule.id === editRuleId ? available.push(a) : disabled.push(a);
    }
  }

  return [
    ...available.sort(byRegistration).map(checkboxOptionTransformer(false)),
    ...disabled.sort(byRegistration).map(checkboxOptionTransformer(true))
  ];
};
