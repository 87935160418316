import PlayCircleOutlined from '@ant-design/icons/PlayCircleOutlined';
import PropTypes from 'prop-types';
import React from 'react';
import { withToolTip } from './withToolTip';
import useLocalStorage from '../../hooks/useLocalStorage';
import { Spin } from 'antd';
import { FlightStatus as ReplayStatus } from '../../constants';

const ReplayTooltipText = Object.freeze({
  [ReplayStatus.AVAILABLE]: 'Flight is available to view in 3D Replay',
  [ReplayStatus.INCOMPLETE]: 'Flight data is incomplete, cell coverage may be required to complete',
  [ReplayStatus.PROCESSING]: 'Flight data is processing and will soon be available for 3D Replay',
  [ReplayStatus.NOT_AVAILABLE]: '3D Replay is not available for this flight',
  [ReplayStatus.IN_REVIEW]: 'Anomalies identified in this flight, not available for replay'
});

export const PlayIcon = ({ openPlayer }) => {
  return (
    <PlayCircleOutlined
      className="replay-icon"
      style={{ fontSize: 24 }}
      onClick={async e => {
        e.stopPropagation();
        // fallback to api request if aircraftData not loaded yet
        openPlayer();
      }}
    />
  );
};

PlayIcon.propTypes = {
  openPlayer: PropTypes.func.isRequired
};

PlayIcon.displayName = 'PlayIcon';

export const ReplayStatusComponent = ({ replayStatus, openPlayer }) => {
  const [iAmDeveloper] = useLocalStorage('iAmDeveloper', false);
  const inReview = iAmDeveloper ? (
    <span>
      <PlayIcon key={1} openPlayer={openPlayer} /> {ReplayStatus.IN_REVIEW}
    </span>
  ) : (
    ReplayStatus.IN_REVIEW
  );

  const statusMap = new Map([
    [ReplayStatus.AVAILABLE, <PlayIcon key={1} openPlayer={openPlayer} />],
    [ReplayStatus.IN_REVIEW, inReview],
    [ReplayStatus.INCOMPLETE, ReplayStatus.INCOMPLETE],
    [ReplayStatus.PROCESSING, ReplayStatus.PROCESSING],

    [ReplayStatus.NOT_AVAILABLE, ReplayStatus.NOT_AVAILABLE],
    //LATER: remove this once 'api.microservice.flight' has been deprecated:
    [ReplayStatus.NOT_AVAILABLE.replace('Available', 'available'), ReplayStatus.NOT_AVAILABLE],

    [ReplayStatus.AWAITING_OFFLOAD, ReplayStatus.AWAITING_OFFLOAD.replace('Offload', 'Upload')],
    [ReplayStatus.NULL, ReplayStatus.NULL]
  ]);

  const getReplayStatus = replayStatus => {
    const status = statusMap.get(replayStatus);
    if (status) {
      return status;
    }

    return <Spin size="small" key={1} />;
  };

  return <div style={{ height: 23 }}>{getReplayStatus(replayStatus)}</div>;
};

ReplayStatusComponent.propTypes = {
  replayStatus: PropTypes.string.isRequired,
  openPlayer: PropTypes.func.isRequired
};

const ReplayStatusWithToolTip = withToolTip(ReplayStatusComponent);

const ReplayColumnCell = ({ replayStatus, openPlayer }) => (
  <ReplayStatusWithToolTip
    replayStatus={replayStatus}
    openPlayer={openPlayer}
    toolTip={ReplayTooltipText[replayStatus]}
  />
);

ReplayColumnCell.propTypes = {
  replayStatus: PropTypes.string.isRequired,
  openPlayer: PropTypes.func.isRequired
};

export default ReplayColumnCell;
