import { EventSeverity, EventSubtype } from '../../redux/types';
import { getInstance } from '../../common/api/spidertracks-sdk';
import { EventExplorerEvent } from '../../common/api/spidertracks-sdk/types/EventExplorerEvent';

export interface EventsExplorerApiPayload {
  startTimestamp: number;
  endTimestamp: number;
  organisationIds: string[];
  aircraft: string[];
  eventSubtypes: EventSubtype[];
  eventSeverities: EventSeverity[];
}

export interface EventLocation {
  name: string;
  lat: number;
  lon: number;
}

//HACK: Remove this code once the duplicate data issue is resolved
const getUniqueEvents = (events: EventExplorerEvent[]): EventExplorerEvent[] => {
  return events.filter((event, index) => {
    return (
      events.findIndex(
        e =>
          e.eventTime === event.eventTime &&
          e.location.lat === event.location.lat &&
          e.location.lng === event.location.lng
      ) === index
    );
  });
};

export default async function getEventsData(filters: EventsExplorerApiPayload) {
  if (!(filters.organisationIds.length && filters.aircraft.length)) {
    return [];
  }

  const apiResponse = await getInstance()
    .getEventsExplorerService()
    .getEvents(filters);

  const uniqueEvents = getUniqueEvents(apiResponse.events);
  return uniqueEvents.map(
    (event: EventExplorerEvent): EventLocation => ({
      name: `${event.eventType}${event.eventSubtype ? ':' + event.eventSubtype : ''}`,
      lat: event.location.lat,
      lon: event.location.lng
    })
  );
}
