import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Form, Checkbox, Row } from 'antd';
import { ConfirmationButtons } from '../../common/form';
import { EventTypeDropdown } from './EventTypeDropdown';
import { AircraftSelector } from '../../common/form/AircraftSelector';
import { buildAircraftOptions } from './utils';
import { ParameterWithThresholds } from './ParameterWithThresholds';
import './styles.scss';

export const validateFormFields = async (
  err,
  fieldValues,
  { setIsSaving, closeDrawer },
  save,
  aircraftOptions
) => {
  try {
    if (!err) {
      // NOTE: `err` is purely a validation error, we don't want those in the console
      setIsSaving(true);
      const body = {
        eventClass: 'fsi',
        eventType: fieldValues.eventType.key
      };

      if (fieldValues.severityDisplayThresholds) {
        body.severityDisplayThresholds = fieldValues.severityDisplayThresholds;
        body.displayThresholds = undefined;
      } else if (fieldValues.displayThresholds) {
        body.displayThresholds = fieldValues.displayThresholds;
        body.severityDisplayThresholds = undefined;
      }

      if (fieldValues.aircraft.length === aircraftOptions.length) {
        body.allAircraft = true;
      } else {
        body.aircraftIds = fieldValues.aircraft;
      }

      await save(body);
      closeDrawer();
    }
  } catch (e) {
    console.error(e);
  } finally {
    setIsSaving(false);
  }
};

export const EventsConfigDrawer = ({
  aircraft,
  isEditForm = false,
  visibility,
  onClose,
  form,
  eventTypes,
  eventParameters,
  eventRules,
  selectedEventRule,
  save,
  insightsSafetyEnabled
}) => {
  const { validateFields, getFieldDecorator, setFieldsValue, setFields } = form;

  const [isSaving, setIsSaving] = useState(false);
  const [selectedEventType, setSelectedEventType] = useState(
    isEditForm
      ? { key: selectedEventRule.eventType, label: selectedEventRule.eventTypeDisplayName }
      : undefined
  );
  const [aircraftOptions, setAircraftOptions] = useState([]);
  const [severityThresholdsChecked, setSeverityThresholdsChecked] = useState(false);

  useEffect(() => {
    if (selectedEventType) {
      form.resetFields();
      setAircraftOptions(
        buildAircraftOptions(
          aircraft,
          eventRules,
          selectedEventType.key,
          isEditForm ? selectedEventRule.id : undefined
        )
      );

      if (insightsSafetyEnabled) {
        setSeverityThresholdsChecked(
          (selectedEventRule && selectedEventRule.severityDisplayThresholds) ||
            selectedEventRule === undefined
        );
      }
    }
  }, [
    aircraft,
    eventRules,
    isEditForm,
    selectedEventType,
    selectedEventRule,
    insightsSafetyEnabled
  ]);

  if (!visibility) {
    return null;
  }

  const closeDrawer = () => {
    onClose();
    setSelectedEventType(undefined);
  };

  const getErrorForThresholdValue = (
    higherThresholdValue,
    lowerThresholdValue,
    formItemFieldName
  ) => {
    const isNegative = higherThresholdValue < 0;
    if (Math.abs(higherThresholdValue) <= Math.abs(lowerThresholdValue)) {
      return {
        displayThresholds: {
          [formItemFieldName]: {
            errors: [
              {
                field: formItemFieldName,
                message: `Must be ${isNegative ? 'less' : 'greater'} than ` + lowerThresholdValue
              }
            ]
          }
        }
      };
    }
  };

  const validateSeverityThresholds = fieldValues => {
    if (fieldValues.severityDisplayThresholds) {
      const fieldNames = Object.keys(fieldValues.severityDisplayThresholds);
      for (const fieldName of fieldNames) {
        const thresholds = fieldValues.severityDisplayThresholds[fieldName];
        if (thresholds.low && thresholds.medium && thresholds.high) {
          const formItemFieldNameMedium = `severityDisplayThresholds.${fieldName}.medium`;
          const formItemFieldNameHigh = `severityDisplayThresholds.${fieldName}.high`;
          const mediumThresholdError = getErrorForThresholdValue(
            thresholds.medium,
            thresholds.low,
            formItemFieldNameMedium
          );
          if (mediumThresholdError) {
            setFields({
              [formItemFieldNameMedium]: {
                value: thresholds.medium,
                errors: [
                  new Error(
                    mediumThresholdError.displayThresholds[
                      formItemFieldNameMedium
                    ].errors[0].message
                  )
                ]
              }
            });

            return mediumThresholdError;
          }

          const highThresholdError = getErrorForThresholdValue(
            thresholds.high,
            thresholds.medium,
            formItemFieldNameHigh
          );
          if (highThresholdError) {
            setFields({
              [formItemFieldNameHigh]: {
                value: thresholds.high,
                errors: [
                  new Error(
                    highThresholdError.displayThresholds[formItemFieldNameHigh].errors[0].message
                  )
                ]
              }
            });
          }

          return highThresholdError;
        }
      }
    }
  };

  const saveEventRule = async e => {
    e.preventDefault();

    validateFields((err, fieldValues) => {
      if (!err) {
        err = validateSeverityThresholds(fieldValues, err);
      }

      validateFormFields(err, fieldValues, { setIsSaving, closeDrawer }, save, aircraftOptions);
    });
  };

  const initialAircraft = isEditForm
    ? selectedEventRule.aircraftIds || aircraftOptions.map(o => o.value)
    : [];
  const parameters = isEditForm ? [{ parameters: selectedEventRule.parameters }] : eventParameters;

  return (
    <Drawer
      className={'events-config-drawer'}
      title={isEditForm ? 'Edit' : 'Add'}
      placement="right"
      closable={true}
      visible={visibility}
      onClose={closeDrawer}
      getContainer={false}
      style={{ position: 'absolute' }}
      width={400}
      bodyStyle={{ width: '356px', margin: '20px 0 0 32px' }}
    >
      <Form onSubmit={saveEventRule} layout="vertical" hideRequiredMark={true}>
        <EventTypeDropdown
          selectedEventType={selectedEventType}
          isEditForm={isEditForm}
          setSelectedEventType={item => setSelectedEventType(item)}
          getFieldDecorator={getFieldDecorator}
          eventTypes={eventTypes}
        />
        {selectedEventType && (
          <AircraftSelector
            form={form}
            initialValue={initialAircraft}
            isEditForm={isEditForm}
            options={aircraftOptions}
            showAllChoice={true}
          />
        )}
        {insightsSafetyEnabled && selectedEventType && (
          <Row style={{ paddingBottom: '2rem' }}>
            <Checkbox
              data-testid="severity-thresholds-checkbox"
              checked={severityThresholdsChecked}
              onChange={() => setSeverityThresholdsChecked(prevState => !prevState)}
            >
              Enable Severity (<span style={{ color: 'green' }}>Low</span>/
              <span style={{ color: 'orange' }}>Medium</span>/
              <span style={{ color: 'red' }}>High</span>)
            </Checkbox>
          </Row>
        )}
        {selectedEventType &&
          parameters
            .filter(parameter => isEditForm || parameter.eventType === selectedEventType.key)
            .map((p, i) => {
              return (
                <ParameterWithThresholds
                  key={`param#${i}`}
                  selectedParameter={p}
                  getFieldDecorator={getFieldDecorator}
                  setFieldsValue={setFieldsValue}
                  severityThresholdsEnabled={severityThresholdsChecked}
                />
              );
            })}
        <ConfirmationButtons onClose={closeDrawer} isSaving={isSaving} />
      </Form>
    </Drawer>
  );
};

EventsConfigDrawer.propTypes = {
  aircraft: PropTypes.array,
  isEditForm: PropTypes.bool,
  visibility: PropTypes.bool,
  onClose: PropTypes.func,
  form: PropTypes.any,
  eventTypes: PropTypes.array,
  eventParameters: PropTypes.array,
  eventRules: PropTypes.array,
  selectedEventRule: PropTypes.object,
  save: PropTypes.func.isRequired,
  insightsSafetyEnabled: PropTypes.bool
};

export default Form.create()(EventsConfigDrawer);
