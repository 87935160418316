// @ts-nocheck
import savitzkyGolay from 'ml-savitzky-golay';
import {Matrix, solve} from 'ml-matrix';
import {Cartesian3, Cartographic, EllipsoidGeodesic,} from 'cesium';
import {buildHalfNormProb, buildLogStdDev, halfNormalProb, rayleighProb} from '../statsHelper';
import {Ahrs, Position} from "../../../common/api/spidertracks-sdk/types/FlightData";
import {OutputData} from "./lockToGround";

const FlightState = {
  parked: 'parked',
  taxiing: 'taxiing',
  inflight: 'inflight',
  sliding: 'sliding'
};
type FlightStateKeys = keyof typeof FlightState;

// Becase PI is overrated anyway
const TAU = Math.PI * 2;

// ====     Constants for statistical processing     ====
// Gps accuracy: https://www.gps.woc.noaa.gov/systems/gps/performance/2016-GPS-SPS-performance-analysis.pdf
// from wikipedia for CEP50, conversion: drms (2d stddev) = CEP50 * 1.2,  rms (stddev) = CEP50 * 0.8493
// from gps.woc.noaa pg. 44 vertical err ~= 1.4 * 2D horizontal err hence, CEP50 * 1.4
const CEP50_TO_HSTD = 1.2;
const CEP50_TO_VSTD = CEP50_TO_HSTD * 1.4;
// Maps info:
// 1) https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0175756#:~:text=The%20GDEM%20data%20are%20the,in%20the%20USA%20%5B14%5D.
// 2) https://www.int-arch-photogramm-remote-sens-spatial-inf-sci.net/XXXIX-B4/291/2012/isprsarchives-XXXIX-B4-291-2012.pdf
// This should maybe be modelled with normal rather than rayleigh distribution, -0.2m mean also exists
// 8.68m seems to be the most accurate global average, however error varies based on location
const E_MAPS_STD = 12;
// From doc for device horizontal CEP50 == 4m
const E_GPS_STD_VERT = 4 * CEP50_TO_VSTD;

// total vertical pos accuracy using pooling std dev of maps_stddev and e_gps_sddev
const E_TOTAL_STDDEV_VERT = Math.sqrt(E_MAPS_STD ** 2 + E_GPS_STD_VERT ** 2);
const altprob = buildHalfNormProb(E_TOTAL_STDDEV_VERT);

// Gps speed accuracy, mean should be 0, https://www.researchgate.net/publication/303759072_Accuracy_of_Speed_Measurements_using_GNSS_in_Challenging_Environments
// From device doc 0.05m/s 50% @ 30m/s, stdev = 50%2derr * 0.8493. Experimentally Vert especially is less accurate at slow speed,
// so fit std dev to log curve based on 30ms and experimental lower speed std dev. Assume SLOW (Maximum std dev) as 1mms
const SLOW_SPEED = 0.001;
const E_V_VERT_30_STD = 0.05 * CEP50_TO_VSTD; //m/s
const E_V_VERT_SLOW_STD = E_V_VERT_30_STD * 4; //experimentally tuned
const vSpeedStdDev = buildLogStdDev(SLOW_SPEED, 30, E_V_VERT_SLOW_STD, E_V_VERT_30_STD);
const vspeedprob = (v: number) => halfNormalProb(v, vSpeedStdDev(v));

const E_V_HORI_30_STD = 0.05 * CEP50_TO_HSTD;
const E_V_HORI_SLOW_STD = E_V_HORI_30_STD * 3; //experimentally tuned
const hSpeedStdDev = buildLogStdDev(SLOW_SPEED, 30, E_V_HORI_SLOW_STD, E_V_HORI_30_STD);
const hspeedprob = (h: number) => rayleighProb(h, hSpeedStdDev(h));

//Higher poly order means better matching to true path so
// less ahrs offset during interpolation, however potentially less smooth
// processing time is also increased with this as polynomial becomes harder to solve
//Edge fixed points is the number of points on each side of the transition used for reference
// more points also makes polynomial more complex increasing processing time
// parked edges are always fixed to 2 point only (1 makes more inituitive sense but 2 works a lot better....)
const TRANSITION_POLY_ORDER = 4;
const TRANSITION_EDGE_FIXED_PTS = 2;
const GPS_TRANSITION_LENGTH = 18;
const GPS_TRANSITION_MIN_LENGTH = 8;
const AHRS_TRANSITION_LENGTH = 4;

//Flight stats is filtered based on mode of this many samples (seconds)
// means landed/flying periods shorter than this are likely to be dropped
const MODE_FILTER_LENGTH = 14;

// ====    Savgol filter configs    ====
const SAVGOL_DERIVATIVE_OPTS = {
  pad: 'post',
  padValue: 'replicate'
};

// p Above these thresholds is counted as flight/horizontal movement
// Note horizonal movement only apply if already decided as not in flight
const HELI_FLIGHT_THRESHOLD = 0.62;
const HELI_SLIDE_THRESHOLD = 0.6;
const FW_FLIGHT_THRESHOLD = 0.8;
const FW_TAXI_THRESHOLD = 0.5;

const LOCK_TO_GND_TIME_THRESHOLD = 120;

interface GPS {
  ts: number;
  lat: number;
  lng: number;
  alt: number;
}

export async function lockAircraftToGround(
  rawGPS: Position[],
  rawAHRS: Ahrs[],
  terrain: number[],
  isFixedWing = false,
  isFirstChunk = false
): Promise<OutputData> {
  // Convert to typed data point for ease of use
  let gps = rawGPS.map(g => ({
    ts: g.timestamp,
    lat: g.latitude,
    lng: g.longitude,
    alt: g.altitude
  }));
  let ahrs = rawAHRS.map(g => ({
    ts: g.timestamp,
    roll: g.roll,
    pitch: g.pitch,
    yaw: g.yaw
  }));

  // Savgol differentiate for vertical velocity
  const verticalVelocity = savitzkyGolay(
    gps.map(gp => gp.alt),
    1,
    SAVGOL_DERIVATIVE_OPTS
  );

  // Calculate lat/longitude distances from origin
  const latdist = gps.map(here => horizonalDistance([here.lat, here.lng], [here.lat, gps[0].lng]));
  const lngdist = gps.map(here => horizonalDistance([here.lat, here.lng], [gps[0].lat, here.lng]));

  // Savgol differentiate lat/lng distance to get velocitiy components, then get velocity magnitude
  const latVelocity = savitzkyGolay(latdist, 1, SAVGOL_DERIVATIVE_OPTS);
  const lngVelocity = savitzkyGolay(lngdist, 1, SAVGOL_DERIVATIVE_OPTS);
  const horizontalVelocity = latVelocity
    .map((latV: number, i: number) => [latV, lngVelocity[i]])
    .map((v: [number, number]) => magnitude(v[0], v[1]));

  const pHVelIfHStill = horizontalVelocity.map(hspeedprob);
  const pVVelIfVStill = verticalVelocity.map(vspeedprob); // p(vertical vel or higher) if vertically stationary

  const altDiff = gps.map((gpspt, i) => gpspt.alt - terrain[i]);
  const pVPosIfGrounded = altDiff.map(a => (a < -0.5 ? 1 : altprob(a))); // p(altitude difference or higher) if at ground level

  let flightStates: FlightStateKeys[] = altDiff.map((alt, i) => {
    // Calculate whether we are moving horizontally
    let pHMoving = 1 - pHVelIfHStill[i];

    // For altitude more than 0.5m below terrain, assume not in air, this is to counteract bad terrain data
    // where velocity implies that it is still moving. In these situations the heli is realistically still going down
    // But terrain would be drawn above it so we want to artificially stop it. -0.5m allows some room for going temporarily underground for smoother animation
    let pIsFlying = 0.0;
    if (alt > -0.5) {
      if (isFixedWing) {
        pIsFlying = 1 - pVPosIfGrounded[i] * pVVelIfVStill[i];
      } else {
        // Helicopter must also be horizontally still while on ground
        pIsFlying = 1 - pVPosIfGrounded[i] * pVVelIfVStill[i] * pHVelIfHStill[i];
      }
    }

    // Determine flight state
    if (!isFixedWing && pIsFlying > HELI_FLIGHT_THRESHOLD) {
      return FlightState.inflight as FlightStateKeys;
    } else if (!isFixedWing && pHMoving > HELI_SLIDE_THRESHOLD) {
      // Allow helis to slide, exists for underground but moving
      return FlightState.sliding as FlightStateKeys;
    } else if (isFixedWing && pIsFlying > FW_FLIGHT_THRESHOLD) {
      return FlightState.inflight as FlightStateKeys;
    } else if (isFixedWing && pHMoving > FW_TAXI_THRESHOLD) {
      return FlightState.taxiing as FlightStateKeys;
    } else {
      return FlightState.parked as FlightStateKeys;
    }
  });

  // Do Overrideing and post processing
  flightStates = applyModeStateFilter(flightStates);

  if (isFirstChunk) {
    flightStates = checkLockFlightStates(flightStates, ahrs);
  }
  const blocks = findOverrideBlocks(gps, ahrs, flightStates);
  overrideAhrsPosition(gps, ahrs, terrain, blocks);
  smoothTransitions(gps, ahrs, blocks);

  const outGPS = gps.map((g, i) => ({
    timestamp: g.ts,
    latitude: g.lat,
    longitude: g.lng,
    altitude: g.alt
  }));
  const outAHRS = ahrs.map((g, i) => ({
    timestamp: g.ts,
    roll: g.roll,
    pitch: g.pitch,
    yaw: g.yaw
  }));
  return { gps: outGPS, ahrs: outAHRS };
}

function applyModeStateFilter(flightStates: FlightStateKeys[]) {
  const halfLen = Math.floor(MODE_FILTER_LENGTH / 2);
  let counts: { [key: string]: number } = {};
  // Initialise section and counts for first 5 points in flight
  const section = flightStates.slice(0, halfLen - 1).map(f => f);
  section.forEach(f => (counts[f] = (counts[f] || 0) + 1));

  return flightStates.map((fs, i) => {
    if (i - halfLen >= 0) {
      const oldSt = section.shift();
      if (oldSt) {
        counts[oldSt] = (counts[oldSt] || 0) - 1;
      }
    }
    if (i + halfLen < flightStates.length) {
      const newSt = flightStates[i + halfLen];
      counts[newSt] = (counts[newSt] || 0) + 1;
      section.push(newSt);
    }

    // Find max
    return Object.keys(counts).reduce((maxKey, key) => {
      // @ts-ignore
      if (counts[key] >= (maxKey ? counts[maxKey] : 0)) {
        // @ts-ignore
        return FlightState[key];
      } else {
        return maxKey;
      }
    }, null);
  });
}

function findOverrideBlocks(gps: GPS[], ahrs: AHRS[], flightStates: FlightStateKeys[]) {
  // Push END to flightStates so last block is processed when state changes to this
  flightStates.push('END');

  const blocks = flightStates.reduce((blocks, state, i) => {
    const lastBlock = blocks[blocks.length - 1] || {
      gps: { end: -1 },
      ahrs: { end: -1 }
    };
    const lastState = flightStates[i - 1] || flightStates[0];

    // Fix position whenever we leave a section of the same state
    if (state !== lastState) {
      const block = {
        gps: { start: lastBlock.gps.end + 1, end: i - 1 }
      };

      // Calculate override for gps
      if (block.gps.start === 0) block.gps.override = block.gps.start;
      else if (block.gps.end >= flightStates.length - 2) block.gps.override = block.gps.end;
      else block.gps.override = Math.floor((block.gps.end + block.gps.start) / 2);

      // State for block is override state just to be safe
      block.state = flightStates[block.gps.override];

      // Correlate ahrs by timestamps not indices to be safe to missing samples etc.
      const startTs = gps[block.gps.start].ts;
      const endTs = gps[block.gps.end].ts;
      const overrideTs = gps[block.gps.override].ts;

      // Find ahrs indicies
      block.ahrs = { start: lastBlock.ahrs.end + 1, end: ahrs.length - 1 };
      for (let j = block.ahrs.start; j < ahrs.length; j++) {
        // Start is final sample <= startTs (generally == startTs)
        if (ahrs[j].ts <= startTs) block.ahrs.start = j;
        // Override is final sample <= override Ts (generally == overrideTs)
        if (ahrs[j].ts <= overrideTs) block.ahrs.override = j;
        // End is final sample <= endTs (generally == endTs)
        if (ahrs[j].ts <= endTs) block.ahrs.end = j;

        // Break when we are >= endTs
        if (ahrs[j].ts >= endTs) break;
      }

      blocks.push(block);
    }

    return blocks;
  }, []);

  // Remove start and END
  flightStates.pop();

  return blocks;
}

function overrideAhrsPosition(gps, ahrs, terrain, blocks) {
  function override(data, blockLimits, overrides) {
    const keys = Object.keys(overrides);
    for (let j = blockLimits.start; j <= blockLimits.end; j++) {
      keys.forEach(key => (data[j][key] = overrides[key]));
    }
  }
  blocks.forEach((block, i) => {
    switch (block.state) {
      case FlightState.parked:
        override(gps, block.gps, {
          lat: gps[block.gps.override].lat,
          lng: gps[block.gps.override].lng,
          alt: terrain[block.gps.override]
        });
        if (block.ahrs.override) {
          override(ahrs, block.ahrs, {
            pitch: 0,
            roll: 0,
            yaw: ahrs[block.ahrs.override].yaw
          });
        }
        break;
      case FlightState.taxiing:
        for (let j = block.gps.start; j <= block.gps.end; j++) {
          gps[j].alt = terrain[j];
        }
        override(ahrs, block.ahrs, { pitch: 0, roll: 0 });
        break;
      case FlightState.sliding:
        // Set slidy cases to just 3m above terrain
        for (let j = block.gps.start; j <= block.gps.end; j++) {
          gps[j].alt = terrain[j] + 3;
        }
        break;
      case FlightState.inflight:
      default:
        break; // Dont lock on inflight
    }
  });
}

function smoothTransitions(gps, ahrs, blocks) {
  function applyPolyInterp(data, start, length, fields, startEdge, endEdge, unwrap = false) {
    let end = start + length + endEdge;
    start = start - startEdge;
    if (end > data.length) end = data.length;
    if (start < 0) start = 0;

    const slc = data.slice(start, end);
    const tsData = slc.map(pt => pt.ts);

    fields.forEach(f => {
      let fieldData = slc.map(pt => pt[f]);
      if (unwrap) fieldData = angleUnwrap(fieldData);

      let interpPoints = leastSquaresPolynomialInterpolation(
        tsData,
        fieldData,
        TRANSITION_POLY_ORDER,
        startEdge,
        endEdge
      );
      if (unwrap) interpPoints = angleRewrap(interpPoints);

      //Assign back to data
      interpPoints.forEach((g, i) => (data[i + start][f] = g));
    });
  }

  blocks.forEach((block, i) => {
    if (i === 0) return;
    const lastBlock = blocks[i - 1];
    // Apply appropriate interpolation for each possible transition
    const isTakeoff = block.state === FlightState.inflight;
    const isLanding = lastBlock.state === FlightState.inflight;
    const isParking = block.state === FlightState.parked;
    const isStartMoving = lastBlock.state === FlightState.parked;

    const glen = GPS_TRANSITION_LENGTH;
    const alen = AHRS_TRANSITION_LENGTH;
    const softEdge = TRANSITION_EDGE_FIXED_PTS;
    const hardEdge = isParking || isStartMoving ? 1 : softEdge;

    if (isTakeoff || isStartMoving) {
      // Smooth new state to fit old state
      applyPolyInterp(gps, block.gps.start, glen, ['lat', 'lng', 'alt'], hardEdge, softEdge);
      applyPolyInterp(
        ahrs,
        block.ahrs.start,
        alen,
        ['roll', 'pitch', 'yaw'],
        hardEdge,
        softEdge,
        'unwrap'
      );
    } else if (isLanding || isParking) {
      // Smooth old state to fit new state
      applyPolyInterp(gps, block.gps.start - glen, glen, ['lat', 'lng', 'alt'], softEdge, hardEdge);
      applyPolyInterp(
        ahrs,
        block.ahrs.start - alen,
        alen,
        ['roll', 'pitch', 'yaw'],
        softEdge,
        hardEdge,
        'unwrap'
      );
    }
  });
}

function angleUnwrap(angles) {
  let last = 0,
    rollovers = 0;
  return angles.reduce((unwrapped, angle) => {
    // Find rollovers
    const diff = angle - last;
    if (diff > Math.PI) rollovers -= 1;
    else if (diff < -Math.PI) rollovers += 1;

    // Unwrap angle based on current rollovers
    unwrapped.push(angle + TAU * rollovers);

    last = angle;
    return unwrapped;
  }, []);
}

function angleRewrap(rotations) {
  return rotations.map(rotation => {
    rotation = rotation % TAU; // Wrap to -360 -> 360
    // Return after wrapping -360->-180 to 0->180 and 360->180 to 0->-180
    if (rotation > Math.PI) return rotation - TAU;
    else if (rotation < -Math.PI) return rotation + TAU;
    else return rotation;
  }, []);
}

function horizonalDistance(geopoint, ref) {
  // Function inputs in Long / Lat order
  const startCoord = Cartesian3.fromDegrees(geopoint[1], geopoint[0]);
  const endCoord = Cartesian3.fromDegrees(ref[1], ref[0]);

  const startCart = Cartographic.fromCartesian(startCoord);
  const endCart = Cartographic.fromCartesian(endCoord);

  return new EllipsoidGeodesic(startCart, endCart).surfaceDistance;
}
function magnitude(...nums) {
  return Math.sqrt(nums.reduce((total, n) => total + n ** 2, 0.0));
}
function sum(array) {
  return array.reduce((s, a) => s + a, 0);
}
function range(end = 0) {
  return Array.from({ length: end }, (_, i) => i);
}

function leastSquaresPolynomialInterpolation(x, y, order = 3, startFp = 2, endFp = 1) {
  if (x.length < startFp + endFp + order || x.length < GPS_TRANSITION_MIN_LENGTH) return y;

  x = x.map(X => X - x[0]);
  const n = order;

  // Extract fixed x/y points
  let xf = [],
    yf = [];
  xf.push(...x.slice(x.length - endFp));
  xf.unshift(...x.slice(0, startFp));
  yf.push(...y.slice(y.length - endFp));
  yf.unshift(...y.slice(0, startFp));
  x = x.slice(startFp, x.length - endFp);
  y = y.slice(startFp, y.length - endFp);

  const eqCount = n + 1 + xf.length;
  const vec = range(eqCount);
  const mat = new Matrix(eqCount, eqCount);

  // x_n = x**np.arange(2 * n + 1)[:, None]
  let x_n = range(2 * n + 1).map(exp => {
    return x.map(X => Math.pow(X, exp));
  });

  // yx_n = np.sum(x_n[:n + 1] * y, axis=1)
  let yx_n = range(n + 1).map(exp => {
    return sum(y.map((Y, i) => Y * x_n[exp][i]));
  });

  // x_n = np.sum(x_n, axis=1)
  x_n = x_n.map((xn, i) => sum(xn));

  // idx = np.arange(n + 1) + np.arange(n + 1)[:, None]
  // mat[:n + 1, :n + 1] = np.take(x_n, idx)
  range(n + 1).forEach(i =>
    range(n + 1).forEach(j => {
      mat.set(i, j, x_n[i + j]);
    })
  );

  // xf_n = xf**np.arange(n + 1)[:, None]
  let xf_n = range(n + 1).map(exp => {
    return xf.map(XF => Math.pow(XF, exp));
  });

  // mat[:n + 1, n + 1:] = xf_n / 2
  for (let i = 0; i < n + 1; i++) {
    for (let j = n + 1; j < eqCount; j++) {
      mat.set(i, j, xf_n[i][j - n - 1] / 2);
    }
  }

  // mat[n + 1:, :n + 1] = xf_n.T
  for (let i = n + 1; i < eqCount; i++) {
    for (let j = 0; j < n + 1; j++) {
      mat.set(i, j, xf_n[j][i - n - 1]);
    }
  }

  // mat[n + 1:, n + 1:] = 0
  for (let i = n + 1; i < eqCount; i++) {
    for (let j = n + 1; j < eqCount; j++) {
      mat.set(i, j, 0);
    }
  }
  // vec[:n + 1] = yx_n
  yx_n.forEach((y, j) => (vec[j] = y));
  // vec[n + 1:] = yf
  yf.forEach((y, j) => (vec[j + n + 1] = y));

  const params = solve(mat, Matrix.columnVector(vec))
    .to1DArray()
    .slice(0, n + 1);

  x.push(...xf.slice(xf.length - endFp));
  x.unshift(...xf.slice(0, startFp));
  const yy = x.map(X => sum(params.map((p, exp) => p * X ** exp)) + x[0]);
  return yy;
}

function checkLockFlightStates(flightstates, ahrs) {
  // Function that will loop through all of the flight states and check them to see whether the aircraft is parked or not
  // and then lock the flight state of each point before it to locked as well to stop bouncing in 3DFR
  // Get the start timestamp
  let taxiPos = 0;
  let slidePos = 0;
  let parkPos = 0;

  // Find the state to lock the initial points too
  for (let i = 0; i < LOCK_TO_GND_TIME_THRESHOLD; i++) {
    if (flightstates[i] === FlightState.parked) {
      parkPos = i;
    } else if (flightstates[i] === FlightState.taxiing) {
      taxiPos = i;
    } else if (flightstates[i] === FlightState.sliding) {
      slidePos = i;
    }
  }

  let startLocking = 0;

  if (parkPos == 0) {
    // If the park state hasn't been found but a taxi or slide state has, then update the flight states
    if (taxiPos != 0 || slidePos != 0) {
      startLocking = Math.max(taxiPos, slidePos);
    }
  } else {
    startLocking = parkPos;
  }

  // Set all flightstate values before i to the flight state at i
  for (let x = 0; x < startLocking; x++) {
    flightstates[x] = flightstates[startLocking];
  }

  return flightstates;
}
